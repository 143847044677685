import "../../App.css";
import tvlIMg from "../../assets/images/tvlv2.svg";
import assetsManagerImg from "../../assets/images/assetsManager.png";
import rocketImg from "../../assets/images/rocket.png";
import { useAccount } from "wagmi";
import { ProgressBar } from "primereact/progressbar";
import { Dropdown } from "primereact/dropdown";
import { ethers } from "ethers";
import { SetStateAction, useEffect, useRef, useState } from "react";
import keyCircleImg from "../../assets/images/keyCircle2.svg";
import seetingCircleImg from "../../assets/images/seetingCircle2.svg";
import { Skeleton } from "primereact/skeleton";
import Decimal from "decimal.js";
import ConnectBtn from "../../component/ConnectBtn";
import RiveraAutoCompoundingVaultV2WhitelistedJson from "../../abi/out/RiveraAutoCompoundingVaultV2Whitelisted.sol/RiveraAutoCompoundingVaultV2Whitelisted.json";
import { Tooltip } from "primereact/tooltip";
import infoCircleImg from "../../assets/images/info_circle.svg";
import quantBull from "../../assets/images/quantBull.png";

import Web3 from "web3";
import { fnum, getTagIcon } from "../common/format";
interface Vault {
  chain: string;
  dashboard: boolean;
}
function Home() {
  const viewDetailsbuttonVal = "View Details --->";
  const managebuttonVal = "Manage --->";
  const [isAdmin, setIsAdmin] = useState(false);
  const [isDataLoadng, setIsDataLoadng] = useState(false);
  const [isWhiteListed, setWhiteListed] = useState(true);
  const [totalTvl, setTotalTvl] = useState("");
  const [tvlCap, setTvlCal] = useState("");
  const [totalVault, setTotalVault] = useState(0);
  const [portfolio, setPortfolio] = useState("");
  const [apiValutList, setapiValutList] = useState<any>([]);

  const { address, isConnected } = useAccount();

  const [tvlCapInUsd, setTvlCalInUsd] = useState("");
  const [allVaults, setAllVaults] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const [filteredVaults, setFilteredVaults] = useState<any[]>([]);

  const filterOptionsStra = [
    { label: "All", value: null },
    { label: "Steady Spread Strategy", value: "Steady Spread Strategy" },
    { label: "Stable Spread Strategy", value: "Stable Spread Strategy" },
    { label: "Asymmetric Bull Strategy", value: "Asymmetric Bull Strategy" },
  ];
  const [filterOptionsDEX, setFilterOptionsDEX] = useState<
    { label: string; value: string | null }[]
  >([{ label: "All", value: null }]);
  useEffect(() => {
    const fetchVaultDatahains = async () => {
      try {
        const vaultData = await fetch("https://api.rivera.money/vaults");
        const vaultJsonData: Vault[] = await vaultData.json();
        const uniqueChains = Array.from(
          new Set(
            vaultJsonData
              .filter((item: Vault) => item.dashboard)
              .map((item: Vault) => item.chain)
          )
        );
        const options = uniqueChains.map((chain) => ({
          label:
            chain.charAt(0).toUpperCase() + chain.slice(1).replace(/-/g, " "),
          value: chain,
        }));

        setFilterOptionsDEX((prevOptions) => [...prevOptions, ...options]);
        console.log("uniqueChains", uniqueChains);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchVaultDatahains();
  }, []);

  useEffect(() => {
    (async () => {
      setTimeout(() => {
        getDeployedValut();
      }, 1000);
    })();
    if (
      address &&
      (address.toLocaleLowerCase() ===
        "0x961ef0b358048d6e34bdd1ace00d72b37b9123d7" ||
        address.toLocaleLowerCase() ===
          "0x546734789339b93220df5e0365d7192e182a72a7")
    ) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [address]);

  const getActualvalue = (num: any, decimal: any) => {
    const roundedNum = Number(
      new Decimal(num.toString()).div(
        new Decimal(Math.pow(10, Number(decimal)))
      )
    );
    return roundedNum;
  };

  const getContractWeb = (address: string, abi: any, provider: any) => {
    const web3 = new Web3(provider);
    return new web3.eth.Contract(abi, address);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  async function getDeployedValut() {
    setIsDataLoadng(true);

    const vaultData = await fetch("https://api.rivera.money/vaults");
    let vaultJsonData = await vaultData.json();

    const aprData = await fetch("https://api.rivera.money/xRIV/vaults");
    let aprDataJsonData = await aprData.json();

    const geckoData = await fetch("https://api.rivera.money/oracle/coingecko");
    let geckoJsonData = await geckoData.json();

    let tvlUsd = 0;
    let totalPortfolio = 0;

    //filter for quant valut and dashboard page visiable condition
    const vaultJsonDataFilterVal = (
      await Promise.all(
        vaultJsonData
          .filter((e: any) => e.dashboard)
          .map(async (valutDetailsInJson: any) => {
            const tvl = getActualvalue(
              valutDetailsInJson.totalAssets,
              valutDetailsInJson.denominationDecimal
            );
            const tvlCap = getActualvalue(
              valutDetailsInJson.totalTvlCap,
              valutDetailsInJson.denominationDecimal
            );
            const sysmbolArray = valutDetailsInJson?.symbol.split("-");
            setTvlCal(tvlCap.toFixed(2));
            const denominationAssetPrice = geckoJsonData.find(
              (e: any) =>
                e.denominationAssetAddress.toLowerCase() ===
                valutDetailsInJson.denominationAssetAddress.toLowerCase()
            );
            const tvlCapInUsdval = new Decimal(tvlCap)
              .mul(new Decimal(denominationAssetPrice?.priceUSD.toString()))
              .toFixed(18); //calculation wth decimal js
            setTvlCalInUsd(tvlCapInUsdval);
            const vaultType = sysmbolArray[sysmbolArray.length - 1];
            const assetArray = valutDetailsInJson?.displayName.split("-");
            const asset1 = assetArray[0].toLowerCase();
            const asset2 = assetArray[1].toLowerCase();

            //tvlUsd = tvlUsd + valutDetailsInJson?.tvlUSD;
            const returnVal = {
              tickLowerPrice: valutDetailsInJson.tickLower,
              tickUpperPrice: valutDetailsInJson.tickUpper,
              currentAllocation: null,
              lastHarvestdate: null,
              feeTier: valutDetailsInJson?.poolFee, //need to discuss
              financeType: valutDetailsInJson?.dex,
              chain: valutDetailsInJson?.chain,
              financeTypeVal: valutDetailsInJson?.dex, //need to discuss
              strategyTypeVal: vaultType, //need to discuss
              strategyType: valutDetailsInJson?.strategyType, //need to discuss
              name: valutDetailsInJson?.displayName,
              assetImg: `../image/assets/${asset1}.png`, //need to discuss
              assetImg2: `../image/assets/${asset2}.png`, //need to discuss

              assetName: valutDetailsInJson?.denominationAsset, //need to discuss
              chainImg: `../image/chain/${valutDetailsInJson?.chain}.png`, //need to discuss
              saftyRating: null, //need to discuss
              tvl: tvl.toFixed(2), //need to discuss
              averageApy:
                aprDataJsonData.find(
                  (e: any) => e.identifier === valutDetailsInJson.identifier
                )?.apr * 100, //need to discuss
              averageLPAPR:
                aprDataJsonData.find(
                  (e: any) => e.identifier === valutDetailsInJson.identifier
                )?.lpAPR * 100, //need to discuss
              valutAddress: valutDetailsInJson?.vaultAddress,
              tvlcap: getActualvalue(
                valutDetailsInJson?.totalTvlCap,
                valutDetailsInJson.denominationDecimal
              ), //need to discuss
              valutApy: null, //need to discuss
              percentage: null, //((tvlInUsd / tvlcapInUsd) * 100).toFixed(4)
              totalPortfolio: null,
              totalOverallreturnVal: null,
              totalAverageApy: null,
              poweredBy: `../image/dex/${valutDetailsInJson?.dex}.png`,
              isStablePair: true,
              isLSDFarming: true,
              isVolatilePair: false,
              isLiquidityMining: false,
              isDynamicStrategy: false,
              isActiveStrategy: false,
              isPeggedStrategy: false,
              isStaticRange: false,
              isWideRange: false,
              currentPrice: 0,
              vaultShare: 0,
              tvlcapInUsd: valutDetailsInJson.tvlcapInUsd,
              tvlInUsd: valutDetailsInJson.tvlUSD,
              identifier: valutDetailsInJson.identifier,
              tags: valutDetailsInJson?.tags,
            };

            return returnVal;
          })
      )
    ).sort((a, b) => b.tvlInUsd - a.tvlInUsd);

    setFilteredVaults(vaultJsonDataFilterVal);
    setapiValutList(vaultJsonDataFilterVal);
    setTotalVault(vaultJsonDataFilterVal.length);
    setAllVaults(vaultJsonData.length);
    // console.log(vaultJsonDataFilterVal, "Lakshay");

    const priceData = await fetch("https://api.rivera.money/oracle/coingecko");
    let priceJsonData = await priceData.json();

    await Promise.all(
      vaultJsonData.map(async (valutDetailsInJson: any) => {
        const tvl = getActualvalue(
          valutDetailsInJson.totalAssets,
          valutDetailsInJson.denominationDecimal
        );
        const tSupply = getActualvalue(
          valutDetailsInJson.totalSupply,
          valutDetailsInJson.denominationDecimal
        );
        if (address) {
          const convertedPrice = priceJsonData.find(
            (e: any) =>
              e.denominationAssetAddress.toLowerCase() ===
              valutDetailsInJson.denominationAssetAddress.toLowerCase()
          )?.priceUSD;
          if (convertedPrice) {
            const web3 = new Web3(valutDetailsInJson.chainRPC);
            const localProvider = web3.eth.currentProvider;
            const vaultContract = getContractWeb(
              valutDetailsInJson.vaultAddress,
              RiveraAutoCompoundingVaultV2WhitelistedJson.abi,
              localProvider
            );
            let share = (await vaultContract.methods
              .balanceOf(address)
              .call()) as any;
            share = getActualvalue(
              share,
              valutDetailsInJson.denominationDecimal
            );
            const userShareVal = (tvl * share) / tSupply;
            const userShareInUsd = userShareVal * convertedPrice;
            totalPortfolio = totalPortfolio + userShareInUsd;
          }
        }
        tvlUsd = tvlUsd + valutDetailsInJson?.tvlUSD;
      })
    );
    setTotalTvl(tvlUsd.toString());
    setPortfolio(totalPortfolio.toFixed(2));
    setIsDataLoadng(false);
  }
  const gotoDiscord = () => {
    const url = "http://discord.gg/DkdZ5DyJga";
    window.open(url, "_blank");
  };

  const getBackgroundClassName = (
    financeTypeVal: any,
    strategyTypeVal: any
  ) => {
    if (financeTypeVal === "FusionX") {
      return strategyTypeVal?.toLowerCase() === "z"
        ? "bckgrnd_img_fusionX_card_stable"
        : "bckgrnd_img_fusionX_card_steady";
    } else if (financeTypeVal === "ApertureSwap") {
      return strategyTypeVal?.toLowerCase() === "z"
        ? "bckgrnd_img_apertureswap_card_stable"
        : "bckgrnd_img_apertureswap_card_steady";
    } else if (financeTypeVal === "Swapsicle") {
      return strategyTypeVal?.toLowerCase() === "z"
        ? "bckgrnd_img_swapsicle_card_stable"
        : "bckgrnd_img_swapsicle_card_steady";
    } else if (financeTypeVal === "Uniswap") {
      return strategyTypeVal?.toLowerCase() === "z"
        ? "bckgrnd_img_Uniswap_card_stable"
        : "bckgrnd_img_Uniswap_card_steady";
    } else if (financeTypeVal === "GlowSwap") {
      return strategyTypeVal?.toLowerCase() === "z"
        ? "bckgrnd_img_GlowSwap_card_stable"
        : "bckgrnd_img_GlowSwap_card_steady";
    } else if (financeTypeVal === "ProxySwap") {
      return strategyTypeVal?.toLowerCase() === "z"
        ? "bckgrnd_img_ProxySwap_card_stable"
        : "bckgrnd_img_ProxySwap_card_steady";
    } else if (financeTypeVal === "Corex") {
      return strategyTypeVal?.toLowerCase() === "z"
        ? "bckgrnd_img_Corex_card_stable"
        : "bckgrnd_img_Corex_card_steady";
    } else {
      return strategyTypeVal?.toLowerCase() === "z"
        ? "bckgrnd_img_agni_card_stable"
        : "bckgrnd_img_agni_card_steady";
    }
  };
  const handleFilterChange = (event: {
    value: SetStateAction<string | null>;
  }) => {
    setSelectedFilter(event.value);
    const filtered = event.value
      ? apiValutList
          .filter(
            (vault: { strategyType: any }) => vault.strategyType === event.value
          )
          .sort(
            (a: { tvlUSD: number }, b: { tvlUSD: number }) =>
              b.tvlUSD - a.tvlUSD
          )
      : apiValutList;
    setFilteredVaults(filtered);
  };

  const handleFilterChangeDEX = (event: {
    value: SetStateAction<string | null>;
  }) => {
    setSelectedFilter(event.value);
    const filtered = event.value
      ? apiValutList
          .filter((vault: { chain: any }) => vault.chain === event.value)
          .sort(
            (a: { tvlUSD: number }, b: { tvlUSD: number }) =>
              b.tvlUSD - a.tvlUSD
          )
      : apiValutList;
    setFilteredVaults(filtered);
  };

  return (
    <>
      <div className="custom-container">
        {isConnected ? (
          <>
            <div className="second_section outer_section_first">
              <div className="dsp_prt_cont">
                {isWhiteListed ? (
                  <>
                    <div className="homePort">
                      <div className="prtfol_mrgn">
                        <div className="first_sec_heder1">
                          Portfolio Holdings
                        </div>
                        <div className="first_sec_heder2">
                          {isDataLoadng ? (
                            <>
                              <Skeleton
                                width="5rem"
                                className="mb-2"
                              ></Skeleton>
                            </>
                          ) : (
                            <>${portfolio}</>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="wdth_40">
                      <div className="holding_header_inner secondary_color mb-2 redHatFont">
                        Join incentivized testnet
                      </div>
                      <div className="mb-3">
                        Help us build Rivera and win exclusive OG badges{" "}
                      </div>
                      <div>
                        <button
                          className="btn btn-riv-primary"
                          onClick={gotoDiscord}
                        >
                          Get Whitelisted
                        </button>
                      </div>
                    </div>
                  </>
                )}
                <div className="homePort_30">
                  <div className="tvl_back pddng_20">
                    <div className="dsp fnt_wgt_600">
                      <span className="op_60">TVL</span>
                      <div className="lqdt_icon_wdth_key">
                        <img src={keyCircleImg} alt="tvl" />
                      </div>
                    </div>
                    <div className="holding_header_inner secondary_color redHatFont">
                      {" "}
                      {isDataLoadng ? (
                        <>
                          <Skeleton width="5rem" className="mb-2"></Skeleton>
                        </>
                      ) : (
                        <>${fnum(totalTvl)}</>
                      )}
                    </div>
                  </div>
                  <div className="dspl_between">
                    <div className="tvl_back pddng_20 width_48">
                      <div className="dsp fnt_wgt_600">
                        <span className="op_60">Vaults</span>
                        <div className="lqdt_icon_wdth_key">
                          <img src={keyCircleImg} alt="tvl" />
                        </div>
                      </div>
                      <div className="holding_header_inner redHatFont">
                        {allVaults}
                      </div>
                    </div>
                    <div className="tvl_back pddng_20 width_48">
                      <div className="dsp fnt_wgt_600">
                        <span className="op_60">Automations </span>
                        <div className="lqdt_icon_wdth_key">
                          <img src={seetingCircleImg} alt="tvl" />
                        </div>
                      </div>
                      <div className="holding_header_inner redHatFont">463</div>
                    </div>
                  </div>
                </div>
                <div>
                  <img src={rocketImg} alt="rocket img" className="rocketImg" />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="second_section outer_section_first">
            <div className="dsp_cont_first_section">
              <div className="wdth_40_first">
                <div className="holding_header_inner mb-2 redHatFont secondary_color">
                  Structured Market Making Vaults
                </div>
                <div className="mb-3 ">
                  Explore strategies that suit your risk profile and easily
                  supply liquidity to the most capital-efficient DEXs.
                </div>
                <div className="mb_20">
                  <ConnectBtn />
                </div>
              </div>
              <div className="wdth_30_first">
                <div className="tvl_back pddng_20">
                  <div className="dsp fnt_wgt_600 ">
                    <span className="op_60">TVL</span>
                    <div className="lqdt_icon_wdth">
                      <img src={tvlIMg} alt="tvl" />
                    </div>
                  </div>
                  <div className="holding_header_inner secondary_color redHatFont">
                    {isDataLoadng ? (
                      <>
                        <Skeleton width="5rem" className="mb-2"></Skeleton>
                      </>
                    ) : (
                      <>${fnum(totalTvl)}</>
                    )}
                  </div>
                </div>
                <div className="dspl_between">
                  <div className="tvl_back pddng_20 width_48">
                    <div className="dsp fnt_wgt_600">
                      <span className="op_60">Vaults</span>
                      <div className="lqdt_icon_wdth_key">
                        <img src={keyCircleImg} alt="tvl" />
                      </div>
                    </div>
                    <div className="holding_header_inner redHatFont">
                      {allVaults}
                    </div>
                  </div>
                  <div className="tvl_back pddng_20 width_48">
                    <div className="dsp fnt_wgt_600">
                      <span className="op_60">Automations </span>
                      <div className="lqdt_icon_wdth_key">
                        <img src={seetingCircleImg} alt="tvl" />
                      </div>
                    </div>
                    <div className="holding_header_inner redHatFont">463</div>
                  </div>
                </div>
              </div>
              <div>
                <img src={rocketImg} alt="rocket img" className="rocketImg" />
              </div>
            </div>
          </div>
        )}

        <div className="flexdown mb-3 mt--1">
          <h1 className="first_sec_heder3 redHatFont ml-1 mt-05">
            {" "}
            Featured vaults
          </h1>
          <div className="filters">
            <Dropdown
              className="dropDown"
              style={{ borderRadius: "0" }}
              value={selectedFilter}
              options={filterOptionsStra}
              onChange={handleFilterChange}
              placeholder="Select Strategy"
              panelStyle={{ borderRadius: "0" }}
            />
            <Dropdown
              className=" mb_5 dropDown"
              value={selectedFilter}
              options={filterOptionsDEX}
              onChange={handleFilterChangeDEX}
              placeholder="Select Chain"
              panelStyle={{ borderRadius: "0" }}
            />
          </div>
        </div>

        <div className="second_section row mt-5 mb-5">
          {filteredVaults.map((e: any, index: any) => {
            return (
              <div className="card_wdth_50" key={index}>
                <div className="first_section outer_section">
                  <div
                    className={`bckgrnd_img_card_fnt ${
                      e.strategyType === "Asymmetric Bull Strategy"
                        ? "bckgrnd_img_quant_card_stable2"
                        : getBackgroundClassName(
                            e.financeTypeVal,
                            e.strategyTypeVal
                          )
                    }`}
                  >
                    <div className="dsp_start">
                      <div className="d-flex align-items-start">
                        <div className="d-flex">
                          <img
                            src={e.assetImg}
                            alt="btc img"
                            className="btc_asst_img_width"
                          />
                          <img
                            src={e.assetImg2}
                            alt="btc img"
                            className="btc_img_width"
                          />
                        </div>
                        <div>
                          <div className="vault_header_font_size">{e.name}</div>
                          <div className="card_snd_clr fnt_14 notMobileDevice">
                            {e?.financeType}
                          </div>
                        </div>
                      </div>
                      <div className="notMobileDevice">
                        <span className="card_snd_clr">Fee Tier</span>
                        <br />
                        <span>{(e.feeTier / Math.pow(10, 4)).toFixed(2)}%</span>
                      </div>
                      <div className="chain_pos_det">
                        <span>
                          <img src={e.chainImg} alt="chain" />
                        </span>
                      </div>
                    </div>
                    <div className="mobileDevice mt-2">
                      <div className="dsp_start">
                        <div>
                          <span className="card_snd_clr">Fee Tier</span>
                          <br />
                          <span>
                            {(e.feeTier / Math.pow(10, 4)).toFixed(2)}%
                          </span>
                        </div>
                        <div>
                          {e.strategyType === "Asymmetric Bull Strategy" ? (
                            <img
                              src={quantBull}
                              alt="Quant Bull"
                              className="mlBull mobBull"
                            />
                          ) : (
                            <img
                              src={e.poweredBy}
                              alt="lock img"
                              className="cashaa logo"
                            />
                          )}
                          {/* <img src={e.poweredBy} alt='lock img' className='cashaa logo' /> */}
                        </div>
                      </div>
                    </div>
                    <div className="vault_sub_header_font_size">
                      {e.strategyType}
                    </div>
                    <div className="pos_dp">
                      <div>
                        <div className="d-flex dspWrap mt-2 mb-4">
                          {isAdmin ? (
                            <>
                              <div className="mr_20">
                                % utilisation
                                <br />
                                <span className="fnt_wgt_600">0%</span>
                              </div>
                              <div>
                                Share Price
                                <br />
                                <span className="fnt_wgt_600">
                                  {e?.vaultShare?.toFixed(2)} {e.assetName}
                                </span>
                              </div>
                            </>
                          ) : (
                            <div
                              className="d-flex dspWrap border3 h-10 "
                              key={index}
                            >
                              {e?.tags.map((tag: string, tagIndex: number) => (
                                <div
                                  className="trdng_outer mr_12"
                                  key={tagIndex}
                                >
                                  <span className="trdng_width whitespace">
                                    <img
                                      src={getTagIcon(tag)}
                                      className="ml_8"
                                      alt={`${tag} img`}
                                    />
                                    {tag}
                                  </span>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="mt-2 mb-4 notMobileDevice">
                        {e.strategyType === "Asymmetric Bull Strategy" ? (
                          <img
                            src={quantBull}
                            alt="Quant Bull"
                            className="mlBull lapBull  mb-1"
                          />
                        ) : (
                          <img
                            src={e.poweredBy}
                            alt="lock img"
                            className="cashaa logo wdth_130"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="pddng_15">
                    <div className="dsp_start mb-4">
                      <div className="wdth_card_55">
                        <div className="mb-1">TVL</div>
                        <span className="secondary_color fnt_wgt_600">
                          {fnum(Number(e.tvl))} {e.assetName}
                        </span>
                        <div className="d-flex">
                          <ProgressBar
                            value={(e.tvl / e.tvlcap) * 100}
                            className="wdth_50"
                            showValue={false}
                          ></ProgressBar>
                          <div className="prgrs_txt">
                            {fnum(Number(e.tvlcap))} {e.assetName}
                          </div>
                        </div>
                      </div>
                      <div className="mr_card_38">
                        <div>APR</div>
                        <div className="red400 d-flex">
                          <div className="holding_val  d-flex whitespace-nowrap">
                            {(e.averageApy + e.averageLPAPR).toFixed(2)} %
                            <img
                              className={`toolTipPerformance${index} ml_5`}
                              src={infoCircleImg}
                              alt="info"
                            />
                            <Tooltip
                              className=""
                              target={`.toolTipPerformance${index}`}
                              mouseTrack
                              mouseTrackLeft={10}
                            >
                              LP: {e.averageLPAPR.toFixed(2)}%<br />
                              {e.averageApy !== 0 && // Check if e.averageApy is not zero
                                `xRIV: ${e.averageApy.toFixed(2)}%`}
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>

                    {isAdmin ? (
                      <>
                        <div className="dsp mb-4">
                          <div>
                            Last Harvest <br />
                            <span className="vault_header_font_size">
                              {e?.lastHarvestdate}
                            </span>
                          </div>
                          <div>
                            Total unharvested rewards <br />
                            <span className="vault_header_font_size"> $0 </span>
                          </div>
                          <div>
                            Harvesting Factor <br />
                            <span className="vault_header_font_size">
                              0.01%
                            </span>
                          </div>
                        </div>
                        <div className="dsp mb-4">
                          <div>
                            Current Price
                            <br />
                            <span className="vault_header_font_size">
                              {e?.currentPrice} {e?.assetName}
                            </span>
                          </div>
                          <div>
                            Current Range <br />
                            <span className="vault_header_font_size">
                              {e?.tickLowerPrice}-{e?.tickUpperPrice}{" "}
                              {e?.assetName}{" "}
                            </span>
                          </div>
                          <div>
                            Current Allocation <br />
                            <span className="vault_header_font_size">
                              {e?.currentAllocation}
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <div className="dsp_around mb-2">
                      <div className="wdth_100">
                        <a
                          onClick={scrollToTop}
                          className="btn btn-riv-secondary view_btn_wdth"
                          href={`#/vault/${e.identifier}`}
                        >
                          {viewDetailsbuttonVal}
                        </a>
                      </div>
                      {isAdmin ? (
                        <>
                          <div className="wdth_100 ml_15">
                            <a
                              className="btn btn-riv-secondary view_btn_wdth"
                              href={`#/vaultManager/${e.identifier}`}
                            >
                              {managebuttonVal}
                            </a>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="second_section outer_section_last last_section_back mb-5">
          <div className="d-flex align-items-center ml_25">
            <div className="">
              <div className="last_header_inner redHatFont">Asset Manager?</div>
              <div className="last_section_desc">
                Provide peace of mind to your investors by offering them
                self-custody vaults. Build & customize powerful yield farming
                and structured products.{" "}
              </div>
              <div>
                <a
                  className="btn btn-riv-secondary earlyacesBtn"
                  href={`https://tally.so/r/m6Lope`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Get Early Access
                </a>
              </div>
            </div>
            <div className="">
              <img
                src={assetsManagerImg}
                alt="assets manager"
                className="rocketImg wdth_275px"
              />
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Home;
