import { useEffect, useRef, useState, useMemo } from 'react';
import { Skeleton } from 'primereact/skeleton';
import { useAccount } from 'wagmi';
import ConnectBtn from '../../component/ConnectBtn';
import coins from '../../assets/images/RIVCOINS.png';
import clock from '../../assets/images/clock.svg';
import dis from '../../assets/images/discord.svg';
import arrow from '../../assets/images/svgviewer-output (1).svg';
import { backendApiUrlBase } from '../../constants/global';
import { useLocation } from 'react-router-dom';

import { RewardGrid } from '../common/commonFAQnRISK';
interface HistoryItem {
    date: string;
    name: string;
    allocation: string;
}

interface ApiResponse {
    _id: string;
    walletAddress: string;
    xriv: number;
    history: HistoryItem[];
}

function parseDateString(dateString: string): Date | null | any {
    const parts = dateString.split('/');
    if (parts.length === 3) {
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1;
        const year = parseInt(parts[2], 10) + 2000;

        return new Date(year, month, day);
    }
    return null;
}

const Reward = () => {
    const location = useLocation();
    const [portfolioBackup, setPortfolioBackup] = useState<number>(0);
    const { portfolio } = location.state || { portfolio: portfolioBackup };
    const { address, isConnected } = useAccount();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [totalVault, setTotalVault] = useState<number>(0);
    const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
    const fAQ = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsDataLoading(true);
                const response = await fetch(`${backendApiUrlBase}xriv/users/${address}`)
                const data = await response.json();
                setApiResponse(data);
                setPortfolioBackup(data.xriv);
                setTotalVault(data.history.reduce((total: number, item: { allocation: any; }) => total + Number(item.allocation), 0));
            } catch (error) {
                console.error('Failed to fetch data', error);
            } finally {
                setIsDataLoading(false);
            }
        };
        if (isConnected) {
            fetchData();
        }
    }, [address, isConnected]);
    const sortedHistory = useMemo(() => {
        if (!apiResponse?.history) return [];
        return [...apiResponse.history].sort(
            (a, b) =>
                parseDateString(b.date)?.getTime() - parseDateString(a.date)?.getTime() || 0
        );
    }, [apiResponse]);

    return (
        <div className='custom-container'>
            {isConnected ? (
                <div className='second_section_xRiv outer_section_first_xRiv'>
                    <div className='dsp_cont_first_section'>
                        <div className='wdth_40_first'>
                            <div className='holding_header_inner mb-2 redHatFont'>xRIV Pre-Mine Program</div>
                            <div className='mb-3'>
                                <div>Total Earnings</div>
                                <div className='holding_header_inner secondary_color redHatFont'>
                                    `${portfolio} xRIV
                                </div>
                            </div>
                        </div>
                        <div className='dsp_around mb-2'>
                            <div className='hightCon'>
                                <a className='btn zin discB1' href='http://discord.gg/DkdZ5DyJga'>
                                    <div className='flexB'>
                                        <img className='imd' src={dis} height={100} alt='discord' />
                                        <h5 className='join redHatFont'>Join Discord</h5>
                                        <img src={arrow} width={44} className='p-1' alt='arrow' />
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div>
                            <img height={200} src={coins} alt='rocket img' className='rocketImg' />
                        </div>
                    </div>
                </div>
            ) : (
                <div className='second_section_xRiv outer_section_first_xRiv'>
                    <div className='dsp_cont_first_section'>
                        <div className='wdth_40_first'>
                            <div className='holding_header_inner mb-2 redHatFont'>xRIV Pre-Mine Program</div>
                            <div className='wdth_40_first'>
                                <ConnectBtn />
                            </div>
                        </div>
                        <div className='dsp_around mb-2'>
                            <div className='hightdisCon'>
                                <a className='btn zin discB1' href='https://discord.com/invite/DkdZ5DyJga'>
                                    <div className='flexB'>
                                        <img className='imd' src={dis} height={85} alt='discord' />
                                        <h5 className='join redHatFont'>Join Discord</h5>
                                        <img src={arrow} width={44} className='p-1' alt='arrow' />
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div>
                            <img height={200} src={coins} alt='rocket img' className='rocketImg' />
                        </div>
                    </div>
                </div>
            )}

            {isConnected && apiResponse ? (
                <div className='reward_grid grid grid-clos-2'>
                    <div className='historyTrue'>
                        <h2 className='redHatFont history_txt1'>History</h2>
                        {sortedHistory.length > 0 ? (
                            <table className='table-container redHatFont'>
                                <thead className='redHatFont'>
                                    <tr>
                                        <th className='redHatFont fnt_size_600'>Date</th>
                                        <th className='redHatFont fnt_size_600'>Event</th>
                                        <th className='redHatFont fnt_size_600'>Allocation</th>
                                    </tr>
                                    <tr className='table-separator'></tr>
                                </thead>
                                <tbody>
                                    {sortedHistory.map((item, index) => (
                                        <tr key={index}>
                                            <td className='fnt_wgt_400'>{item.date}</td>
                                            <td className='fnt_wgt_400'>{item.name}</td>
                                            <td className='secondary_color fnt_wgt_600 whitespace-nowrap'>{item.allocation} xRIV</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <div className='clock-container hist_empty'>
                                <div className='heading-container'>
                                    <h1 className='heading redHatFont fnt_size_600'>Date</h1>
                                    <h1 className='heading redHatFont fnt_size_600'>Event</h1>
                                    <h1 className='heading redHatFont fnt_size_600'>Allocation</h1>
                                </div>
                                <div className='separator-line'></div>
                                <div className='centered-content'>
                                    <img src={clock} alt='Clock Icon' className='clock-image' />
                                    <p className='Clocktext redHatFont fnt_size_600'>
                                        Deposit in Rivera Vaults to earn xRIV.<br />
                                        Earned rewards will be visible here.
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                    <RewardGrid />
                </div>
            ) : (
                <div className='reward_grid grid grid-clos-2'>
                    <div className='historyTrue'>
                        <h2 className='history_txt mb-4 redHatFont'>History</h2>
                        {isDataLoading ? (
                            <div className='skel'>
                                <Skeleton width='20%' height='20px' />
                                <Skeleton width='20%' height='20px' />
                                <Skeleton width='20%' height='20px' />
                            </div>
                        ) : (
                            <div className='clock-container hist_emptyNOC'>
                                <div className='heading-container'>
                                    <h1 className='heading redHatFont fnt_size_600'>Date</h1>
                                    <h1 className='heading redHatFont fnt_size_600'>Event</h1>
                                    <h1 className='heading redHatFont fnt_size_600'>Allocation</h1>
                                </div>
                                <div className='separator-line'></div>
                                <div className='centered-content'>
                                    <img src={clock} alt='Clock Icon' className='clock-image' />
                                    <p className='Clocktext redHatFont fnt_size_600'>
                                        Deposit in Rivera Vaults to earn xRIV.<br />
                                        Earned rewards will be visible here.
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                    <RewardGrid />
                </div>
            )}
        </div>
    );
}

export default Reward;
