import { ethers } from "ethers";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAccount, useNetwork, useSigner, useSwitchNetwork } from "wagmi";
import { TabView, TabPanel } from "primereact/tabview";
import { ProgressBar } from "primereact/progressbar";
import globeImg from "../../assets/images/globe.png";
import arrowUpImg from "../../assets/images/arrow-up-right.png";
import eternalLinkImg from "../../assets/images/external-link.png";
import erc20Json from "../../abi/out/ERC20.sol/ERC20.json";
import dollarImg from "../../assets/images/dollar.png";
import zapIMg from "../../assets/images/zap.png";
import riveraAutoCompoundingVaultV2WhitelistedJson from "../../abi/out/RiveraAutoCompoundingVaultV2Whitelisted.sol/RiveraAutoCompoundingVaultV2Whitelisted.json";
import StablePairColorImg from "../../assets/images/StablePairColor.svg";
import VolatilePairColorImg from "../../assets/images/volatilePair.png";
import dynamicStrategyImg from "../../assets/images/dynamicStrategy.png";
import almImg from "../../assets/images/alm.svg";
import LSDFarmingImg from "../../assets/images/LSDFarming.svg";
import { Toast } from "primereact/toast";
import Decimal from "decimal.js";
import depositTabImg from "../../assets/images/depositTab.svg";
import withdrwaTabImg from "../../assets/images/withdrwaTab.svg";
import waitingImg from "../../assets/images/waiting.gif";
import successfullImg from "../../assets/images/successfull.gif";
import { Dialog } from "primereact/dialog";
import switchImage from "../../assets/images/toggleSwitch.svg";
import ConnectBtn from "../../component/ConnectBtn";
import infoCircleImg from "../../assets/images/info_circle.svg";
import { Tooltip } from "primereact/tooltip";
import { Skeleton } from "primereact/skeleton";
import ProgressComparison from "./ProgressBar";
import { fnum, getTagIcon } from "./format";
import { CommonFAQ, Risk } from "./commonFAQnRISK";
import Web3 from "web3";
import { isIdentifier } from "typescript";

interface Product {
  id: string;
  code: string;
  name: string;
  description: string;
  image: string;
  price: number;
  category: string;
  quantity: number;
  inventoryStatus: string;
  rating: number;
}

export default function VaultDetails() {
  const [isDataLoadng, setIsDataLoadng] = useState(false);
  const overview = useRef(null);
  const strategy = useRef(null);
  const yieldToken = useRef(null);
  const risk = useRef(null);
  const portfolioManager = useRef(null);
  const fees = useRef(null);
  const fAQ = useRef(null);

  const toast = useRef<Toast>(null);
  const [deatils, setDeatils] = useState({
    vaultName: "",
    symbol: "",
    assetName: "",
    assetImg: "",
    assetImg2: "",
    denoImage: "",
    asset1: "",
    asset2: "",
    tvl: "",
    tvlInusd: "",
    holding: "",
    networkImg: "",
    fundManger: "",
    vaultReturn: "",
    vaultOwner: "",
    rpc: "",
    vaultAddress: "",
    denominationDecimal: 0,
    explorerLink: "",
    poweredBy: "",
    chainId: "",
    chainName: "",
    lpApr: 0,
    totalSupply: 0,
    currentTick: 0,
    xirvApr: 0,
    totalApr: 0,
    feeTier: Number,
    partnerFee: 0,
    feeDecimals: 0,
    withdrawFee: 0,
    fundManagerFee: 0,
    protocolFee: 0,
    withdrawFeeDecimals: 0,
    buyToken: "",
    detailsNew: "",
    website: "",
    dex: [],
    tags: [],
    apr: [],
    strategyType: [],
    description: "",
    stTypes: "",
    tickLower: 0,
    tickUpper: 0,
    lp0Decimal: 0,
    lp1Decimal: 0,
    lp0Name: "",
    lp1Name: "",
    stake: "",
  });
  const [transactionType, setTransactionType] = useState("");
  const [isWhiteListed, setWhiteListed] = useState(false);
  const [maxLimit, setMaxLimit] = useState<any>(null);
  const [valutJsonData, setValutJsonData] = useState<any>();
  const [tvlCap, setTvlCal] = useState("");

  const [tvlCapInUsd, setTvlCalInUsd] = useState("");
  const [vaultApy, setVaultApy] = useState<any>(null);
  const [userShare, setUserShare] = useState("0");
  const [userPrice, setUserPrice] = useState("0");
  const [isApproved, setisApproved] = useState(false);
  const [depositAmout, setdepositAmout] = useState<any>("0");
  const [withdrawAmout, setwithdrawAmout] = useState("0");
  const { data: signer } = useSigner();
  const { address, isConnected } = useAccount();
  let { vaultAddress } = useParams();
  const [loading, setLoading] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [clockwise, setClockwise] = useState(true);
  const [isTransactionOnGoing, setIsTransactionOnGoing] = useState(false);
  const switchNetwork = useSwitchNetwork();
  const { chain } = useNetwork();

  const [walletBalance, setWalletBalance] = useState<any>(null);
  const [assetAbiVal, setAssetAbi] = useState(null);
  const [approvalWaitingVisible, setapprovalWaitingVisible] =
    useState<boolean>(false);
  const [approvalSuccessfullVisible, setapprovalSuccessfullVisible] =
    useState<boolean>(false);
  const [openMetamaskVisible, setOpenMetamaskVisible] =
    useState<boolean>(false);
  const [depositWaitingVisible, setdepositWaitingVisible] =
    useState<boolean>(false);
  const [depositSuccessfullVisible, setdepositSuccessfullVisible] =
    useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    setIsDataLoadng(true);
    getBakcendApiDetails();
    (async () => {
      const response = await fetch("/vaultDetails.json");
      const valutData = await response.json();
      setValutJsonData(valutData[vaultAddress as string]);
    })();
  }, [address]);
  const getActualvalue = (num: any, decimal: any) => {
    const roundedNum = Number(
      new Decimal(num.toString()).div(
        new Decimal(Math.pow(10, Number(decimal)))
      )
    );
    return roundedNum;
  };

  const getBakcendApiDetails = async () => {
    const vaultData = await fetch(
      `https://api.rivera.money/vaults/?identifier=${vaultAddress}`
    );
    let vaultJsonData = await vaultData.json();
    let vaultJsonSingleData = vaultJsonData.find(
      (e: any) =>
        e.identifier.toLowerCase() === (vaultAddress as string).toLowerCase()
    );

    const geckoData = await fetch("https://api.rivera.money/oracle/coingecko");
    let geckoJsonData = await geckoData.json();

    const denominationAssetPrice = geckoJsonData.find(
      (e: any) =>
        e.denominationAssetAddress.toLowerCase() ===
        vaultJsonSingleData.denominationAssetAddress.toLowerCase()
    );

    const tvl = getActualvalue(
      vaultJsonSingleData.totalAssets,
      vaultJsonSingleData.denominationDecimal
    );
    const tvlCap = getActualvalue(
      vaultJsonSingleData.totalTvlCap,
      vaultJsonSingleData.denominationDecimal
    );

    const currentShareValurInMnt = new Decimal(
      vaultJsonSingleData?.totalAssets
    ).div(new Decimal(vaultJsonSingleData?.totalSupply));

    const denoImg = vaultJsonSingleData?.denominationAsset;
    const asset1 = vaultJsonSingleData.lpToken0.name.toLowerCase();
    const asset2 = vaultJsonSingleData.lpToken1.name.toLowerCase();

    const website = vaultJsonSingleData?.dex?.website || "";

    const detailsValFirstV2 = {
      vaultName: vaultJsonSingleData?.displayName,
      symbol: vaultJsonSingleData?.symbol,
      assetName: vaultJsonSingleData?.denominationAsset,
      assetImg: `../image/assets/${asset1.toLowerCase()}.png`,
      assetImg2: `../image/assets/${asset2.toLowerCase()}.png`,
      denoImage: `../image/assets/${denoImg.toLowerCase()}.png`,
      asset1: `${asset1}`,
      asset2: `${asset2}`,
      tvl: tvl.toFixed(2),
      tvlInusd: vaultJsonSingleData?.tvlUSD,
      holding: "",
      totalSupply: vaultJsonSingleData?.totalSupply,
      networkImg: `../image/chain/${vaultJsonSingleData?.chain?.chain}.png`,
      fundManger: vaultJsonSingleData?.manager,
      vaultReturn: Number(currentShareValurInMnt).toFixed(2),
      vaultOwner: vaultJsonSingleData?.owner,
      poweredBy: `../image/dex/${vaultJsonSingleData?.dex?.dex}.png`,
      rpc: vaultJsonSingleData?.chainRPC,
      currentTick: vaultJsonSingleData?.currentTick,
      vaultAddress: vaultJsonSingleData?.vaultAddress,
      denominationDecimal: vaultJsonSingleData?.denominationDecimal,
      explorerLink: vaultJsonSingleData?.chain?.chainExplorer,
      chainId: vaultJsonSingleData?.chain?.chainID,
      chainName: vaultJsonSingleData?.chain?.displayName,
      lpApr: vaultJsonSingleData?.apr?.lpAPR * 100,
      xirvApr: vaultJsonSingleData?.apr?.apr * 100,
      totalApr:
        vaultJsonSingleData?.apr?.apr + vaultJsonSingleData?.apr?.lpAPR * 100,
      feeTier: vaultJsonSingleData?.poolFee,
      partnerFee: parseFloat(vaultJsonSingleData?.partnerFee || "0"),
      feeDecimals: parseFloat(vaultJsonSingleData?.feeDecimals || "0"),
      withdrawFee: parseFloat(vaultJsonSingleData?.withdrawFee || "0"),
      fundManagerFee: parseFloat(vaultJsonSingleData?.fundManagerFee || "0"),
      protocolFee: vaultJsonSingleData?.protocolFee,
      withdrawFeeDecimals: vaultJsonSingleData?.withdrawFeeDecimals,
      tags: vaultJsonSingleData?.tags,
      apr: vaultJsonSingleData?.apr,
      dex: vaultJsonSingleData?.dex,
      buyToken: vaultJsonSingleData?.dex?.buyToken,
      website: website,
      tickUpper: vaultJsonSingleData?.tickUpper,
      tickLower: vaultJsonSingleData?.tickLower,
      lp0Decimal: vaultJsonSingleData?.lpToken0.decimals,
      lp1Decimal: vaultJsonSingleData?.lpToken1.decimals,
      lp0Name: vaultJsonSingleData?.lpToken0.name,
      lp1Name: vaultJsonSingleData?.lpToken0.name,
      detailsNew: vaultJsonSingleData?.dex.details,
      strategyType: vaultJsonSingleData?.strategyType,
      stTypes: vaultJsonSingleData?.strategyType.strategyType,
      description: vaultJsonSingleData?.strategyType.description,
      stake: vaultJsonSingleData?.stake,
    };

    setVaultApy(Number(detailsValFirstV2?.totalApr));
    setDeatils(detailsValFirstV2);
    setTvlCal(tvlCap.toFixed(2));
    setLoading(true);

    const tvlCapInUsdval = new Decimal(tvlCap)
      .mul(new Decimal(denominationAssetPrice?.priceUSD.toString()))
      .toFixed(18); //calculation wth decimal js
    setTvlCalInUsd(tvlCapInUsdval);
    let localProvider;
    let vaultContract;
    const web3 = new Web3(vaultJsonSingleData.chainRPC);
    localProvider = web3.eth.currentProvider;
    vaultContract = getContract(
      vaultJsonSingleData.vaultAddress as string,
      riveraAutoCompoundingVaultV2WhitelistedJson.abi,
      localProvider
    );
    let priceSec = (await vaultContract.methods
      .convertToAssets(1000)
      .call()) as any;
    setUserPrice(ethers.utils.formatUnits(priceSec));

    if (address) {
      setWhiteListed(true);
      let localProvider;
      let vaultContract;
      const web3 = new Web3(vaultJsonSingleData.chainRPC);
      localProvider = web3.eth.currentProvider;
      vaultContract = getContract(
        vaultJsonSingleData.vaultAddress as string,
        riveraAutoCompoundingVaultV2WhitelistedJson.abi,
        localProvider
      );

      //ger user share
      let share = (await vaultContract.methods
        .balanceOf(address)
        .call()) as any;
      let userShareVal = (await vaultContract.methods
        .convertToAssets(share)
        .call()) as any;

      setUserShare(
        ethers.utils.formatUnits(
          userShareVal,
          Number(vaultJsonSingleData.denominationDecimal)
        )
      );

      const erc20Contract = getContract(
        vaultJsonSingleData?.denominationAssetAddress,
        erc20Json.abi,
        localProvider
      );
      const allowance = (await erc20Contract.methods
        .allowance(address, vaultJsonSingleData.vaultAddress)
        .call()) as any;

      setMaxLimit(
        ethers.utils.formatUnits(
          allowance,
          vaultJsonSingleData.denominationDecimal
        )
      );
      const maxLimitVal = new Decimal(
        ethers.utils.formatUnits(
          allowance,
          vaultJsonSingleData.denominationDecimal
        )
      );
      const despositAmtVal = new Decimal(depositAmout);

      if (despositAmtVal.gt(maxLimitVal)) {
        setisApproved(false);
      } else {
        setisApproved(true);
      }

      const response = await fetch("./abi/wmntAbi.json");
      const data = await response.json();
      setAssetAbi(data?.abi);
      assetValueUpdate(data?.abi, detailsValFirstV2);
    }
    setIsDataLoadng(false);
    setLoading(false);
  };

  const checkAllowance = async () => {
    let localProvider;
    let vaultContract;
    const web3 = new Web3(deatils.rpc);
    localProvider = web3.eth.currentProvider;
    vaultContract = getContract(
      deatils.vaultAddress as string,
      riveraAutoCompoundingVaultV2WhitelistedJson.abi,
      localProvider
    );

    const valutAssetAdress = (await vaultContract.methods
      .asset()
      .call()) as any;

    const erc20Contract = getContract(
      valutAssetAdress,
      erc20Json.abi,
      localProvider
    );
    const allowance = (await erc20Contract.methods
      .allowance(address, deatils.vaultAddress)
      .call()) as any; //address:- login user address  //assetAdress:-valut asset address

    setMaxLimit(
      ethers.utils.formatUnits(allowance, deatils.denominationDecimal)
    );
    const maxLimitVal = new Decimal(
      ethers.utils.formatUnits(allowance, deatils.denominationDecimal)
    );
    const despositAmtVal = new Decimal(depositAmout);

    if (despositAmtVal.gt(maxLimitVal)) {
      setisApproved(false);
      return false;
    } else {
      setisApproved(true);
      return true;
    }
  };

  const assetValueUpdate = async (abi?: any, localDeatils?: any) => {
    let localProvider;
    let vaultContract;

    if (localDeatils) {
      const web3 = new Web3(localDeatils.rpc);
      localProvider = web3.eth.currentProvider;
      vaultContract = getContract(
        localDeatils.vaultAddress as string,
        riveraAutoCompoundingVaultV2WhitelistedJson.abi,
        localProvider
      );
    } else {
      const web3 = new Web3(deatils.rpc);
      localProvider = web3.eth.currentProvider;
      vaultContract = getContract(
        deatils.vaultAddress as string,
        riveraAutoCompoundingVaultV2WhitelistedJson.abi,
        localProvider
      );
    }
    const valutAssetAdress = (await vaultContract.methods
      .asset()
      .call()) as any;

    if (abi) {
      const asstsContract = getContract(valutAssetAdress, abi, localProvider);
      const balance = (await asstsContract.methods
        .balanceOf(address)
        .call()) as any;
      setWalletBalance(
        ethers.utils.formatUnits(balance, localDeatils.denominationDecimal)
      );
    } else {
      const asstsContract = getContract(
        valutAssetAdress,
        assetAbiVal,
        localProvider
      );
      const balance = (await asstsContract.methods
        .balanceOf(address)
        .call()) as any;
      setWalletBalance(
        ethers.utils.formatUnits(balance, deatils.denominationDecimal)
      );
    }
  };
  const approveIntilize = async () => {
    const contract = getEtherContract(
      deatils.vaultAddress as string,
      riveraAutoCompoundingVaultV2WhitelistedJson.abi,
      signer
    );
    const assetAdress = await contract.asset();
    await approve(assetAdress);
  };

  async function approve(assetsAddress: string) {
    const contract = getEtherContract(assetsAddress, erc20Json.abi, signer);
    try {
      setIsTransactionOnGoing(true);

      const aa = Decimal.pow(10, deatils.denominationDecimal);
      const bb = new Decimal(depositAmout);

      const convertedAmount = bb.mul(aa).toFixed();

      let aprvTxt;
      if (deatils.chainName.toLowerCase() === "mantle") {
        const estimatedGasFee = await contract.estimateGas.approve(
          deatils.vaultAddress,
          convertedAmount
        );
        aprvTxt = await contract.approve(
          deatils.vaultAddress,
          convertedAmount,
          {
            gasLimit: estimatedGasFee,
          }
        );
      } else {
        aprvTxt = await contract.approve(deatils.vaultAddress, convertedAmount);
      }
      setapprovalWaitingVisible(true);
      await aprvTxt
        .wait()
        .then(async (e: any) => {
          setapprovalWaitingVisible(false);
          setapprovalSuccessfullVisible(true);
          const isApproveVal = await checkAllowance();
          await deposit(isApproveVal);

          setIsTransactionOnGoing(false);
        })
        .catch((error: any) => {
          setIsTransactionOnGoing(false);
          showError("Something went wrong");
        });
    } catch (err: any) {
      console.log("revert reason:", err.message);
    }
  }
  const deposit = async (isApprovedVal?: boolean) => {
    setTransactionType("Deposit");
    const despositAmtVal = new Decimal(depositAmout);
    const walletBalanceVal = new Decimal(walletBalance);

    if (isApprovedVal !== undefined && !isApprovedVal) {
      const message =
        "Approved amount is lower than Deposit amount. Please try again.";
      showWarn("Warning", message);
      return;
    }

    if (despositAmtVal.lt(new Decimal("0.0001"))) {
      const message = "Min Limit 0.0001";
      showWarn("Warning", message);
      return;
    }

    if (despositAmtVal.gt(walletBalanceVal)) {
      const message = "Insufficient Balance";
      showWarn("Warning", message);
      return;
    }

    if (Number(deatils.tvl) + Number(depositAmout) > Number(tvlCap)) {
      showWarn("Warning", "Vault Capacity Reached");
      return;
    }
    setIsTransactionOnGoing(true);

    const contract = getEtherContract(
      deatils.vaultAddress as string,
      riveraAutoCompoundingVaultV2WhitelistedJson.abi,
      signer
    );

    const aa = Decimal.pow(10, deatils.denominationDecimal);
    const bb = new Decimal(depositAmout);

    const convertedAmount = bb.mul(aa).toFixed();
    let aprvTxt;
    if (deatils.chainName.toLowerCase() === "mantle") {
      const estimatedGasFee = await contract.estimateGas.deposit(
        convertedAmount,
        address
      );
      aprvTxt = await contract.deposit(convertedAmount, address, {
        gasLimit: estimatedGasFee,
      });
    } else {
      aprvTxt = await contract.deposit(convertedAmount, address, {
        gasLimit: 3000000,
      });
    }
    const intValue = aprvTxt as any;
    setapprovalSuccessfullVisible(false);
    setdepositWaitingVisible(true);
    await intValue
      .wait()
      .then(async (e: any) => {
        setdepositWaitingVisible(false);

        setdepositSuccessfullVisible(true);
        await getBakcendApiDetails();
        setIsTransactionOnGoing(false);
      })
      .catch((error: any) => {
        setLoading(false);
        setIsTransactionOnGoing(false);
        showError("Something went wrong");
      });
  };

  const withdraw = async () => {
    setTransactionType("Withdraw");
    const withdrawAmoutVal = new Decimal(withdrawAmout);
    const userHohlding = new Decimal(userShare);

    if (withdrawAmoutVal.lt(new Decimal("0.0001"))) {
      const message = "Min Limit 0.0001";
      showWarn("Warning", message);
      return;
    }

    if (withdrawAmoutVal.gt(userHohlding)) {
      const message = "Insufficient Holdings";
      showWarn("Warning", message);
      return;
    }
    const contract = getEtherContract(
      deatils.vaultAddress as string,
      riveraAutoCompoundingVaultV2WhitelistedJson.abi,
      signer
    );
    const aa = Decimal.pow(10, deatils.denominationDecimal);
    const bb = new Decimal(withdrawAmout);

    const convertedAmount = bb.mul(aa).toFixed();

    setIsTransactionOnGoing(true);
    let aprvTxt;
    if (deatils.chainName.toLowerCase() === "mantle") {
      const estimatedGasFee = await contract.estimateGas.withdraw(
        convertedAmount,
        address,
        address
      );
      aprvTxt = await contract.withdraw(convertedAmount, address, address, {
        gasLimit: estimatedGasFee,
      });
    } else {
      aprvTxt = await contract.withdraw(convertedAmount, address, address, {
        gasLimit: 3000000,
      });
    }
    const intValue = aprvTxt as any;
    setdepositWaitingVisible(true);
    await intValue
      .wait()
      .then(async (e: any) => {
        setdepositWaitingVisible(false);
        setdepositSuccessfullVisible(true);
        await getBakcendApiDetails();
        setIsTransactionOnGoing(false);
      })
      .catch((error: any) => {
        setIsTransactionOnGoing(false);
        showError("Something went wrong");
      });
  };

  const handledepositAmoutChange = (event: any) => {
    let val = event.target.value;
    if (val === "") {
      val = "0";
    }
    const enteredVal = new Decimal(val);
    const mxlmtVal = new Decimal(maxLimit);
    if (enteredVal.gt(mxlmtVal)) {
      setisApproved(false);
    } else {
      setisApproved(true);
    }
    setdepositAmout(event.target.value);
  };

  const updateDepositMax = () => {
    setdepositAmout(walletBalance);
    const walletBalanceVal = new Decimal(walletBalance);
    const maxLimitVal = new Decimal(maxLimit);
    if (walletBalanceVal.gt(maxLimitVal)) {
      setisApproved(false);
    } else {
      setisApproved(true);
    }
  };

  const handlewithdrawAmoutChange = (event: any) => {
    setwithdrawAmout(event.target.value);
  };

  const updateWithdrawMax = () => {
    setwithdrawAmout(userShare);
  };

  const showSuccess = (message: string) => {
    toast.current?.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  };

  const showWarn = (header: string, message: string) => {
    toast.current?.show({
      severity: "warn",
      summary: header,
      detail: message,
      life: 3000,
    });
  };

  const showError = (message: string) => {
    toast.current?.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };

  const getContract = (address: string, abi: any, provider: any) => {
    const web3 = new Web3(provider);
    return new web3.eth.Contract(abi, address);
  };

  const getEtherContract = (address: string, abi: any, provider: any) => {
    return new ethers.Contract(address, abi, provider);
  };

  const networkSwitchHandler = (networkId: number) => {
    (switchNetwork as any).switchNetwork(networkId);
  };

  const goToUrl = (url: any) => {
    window.open(url, "_blank");
  };

  interface CustomTabHeaderProps {
    icon: string;
    title: string;
  }

  const CustomTabHeader: React.FC<CustomTabHeaderProps> = ({ icon, title }) => {
    return (
      <>
        {icon && (
          <img
            src={icon}
            alt="Icon"
            style={{ marginRight: "10px", width: "28px" }}
          />
        )}
        <span>{title}</span>
      </>
    );
  };

  const sections = document.querySelectorAll("section[id]");
  window.addEventListener("scroll", navHighlighter);

  function navHighlighter() {
    let scrollY = window.pageYOffset;
    sections.forEach((current: any) => {
      const sectionHeight = current["offsetHeight"];
      const sectionTop =
        current.getBoundingClientRect().top + window.pageYOffset - 100;
      const sectionId = current.getAttribute("id");

      if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
        document.querySelector("#li_" + sectionId)?.classList.add("active");
      } else {
        document.querySelector("#li_" + sectionId)?.classList.remove("active");
      }
    });
  }

  const scrollToSection = (elementRef: any) => {
    window.scrollTo({
      top: elementRef.current.offsetTop - 70,
      behavior: "smooth",
    });
  };

  const getCurrentAllocation = (a: number, b: number, k: number): number => {
    const numerator = Math.sqrt(b / k) * (Math.sqrt(k) - Math.sqrt(a));
    const denominator = Math.sqrt(b) - Math.sqrt(k);
    return numerator / denominator;
  };

  const zeroDeno = deatils.lp0Decimal || 0;
  const oneDeno = deatils.lp1Decimal || 0;
  const Diff = Math.pow(10, zeroDeno - oneDeno);
  const currentPrice = Math.pow(1.0001, deatils.currentTick);
  const newcurrentPrice = currentPrice * Diff;
  const lowerRangePrice = Math.pow(1.0001, deatils.tickLower) * Diff;
  const upperRangePrice = Math.pow(1.0001, deatils.tickUpper) * Diff;
  const currentAllocation = getCurrentAllocation(
    lowerRangePrice,
    upperRangePrice,
    newcurrentPrice
  );
  const token0Allocation = 100 / (1 + Math.abs(currentAllocation));
  const token1Allocation = 100 - 100 / (1 + Math.abs(currentAllocation));
  const share1 = token0Allocation / 100;
  const share2 = token1Allocation / 100;
  const originalValues = {
    newcurrentPrice: newcurrentPrice,
    lowerRangePrice: lowerRangePrice,
    upperRangePrice: upperRangePrice,
    token0Allocation: token0Allocation,
    token1Allocation: token1Allocation,
    deatils: {
      asset1: deatils.asset1.toLocaleUpperCase(),
      asset2: deatils.asset2.toLocaleUpperCase(),
      assetImg: deatils.assetImg,
      assetImg2: deatils.assetImg2,
      tvlInusd1: (Number(deatils.tvlInusd) * share1).toFixed(2),
      tvlInusd2: (Number(deatils.tvlInusd) * share2).toFixed(2),
    },
  };
  const toggledValues = {
    newcurrentPrice: 1 / newcurrentPrice,
    lowerRangePrice: 1 / upperRangePrice,
    upperRangePrice: 1 / lowerRangePrice,
    token0Allocation: token1Allocation,
    token1Allocation: token0Allocation,
    deatils: {
      asset1: deatils.asset2.toLocaleUpperCase(),
      asset2: deatils.asset1.toLocaleUpperCase(),
      assetImg: deatils.assetImg2,
      assetImg2: deatils.assetImg,
      tvlInusd1: (Number(deatils.tvlInusd) * share2).toFixed(2),
      tvlInusd2: (Number(deatils.tvlInusd) * share1).toFixed(2),
    },
  };

  const values = toggled ? toggledValues : originalValues;

  const toggleValues = () => {
    setToggled(!toggled);
    setRotation(clockwise ? rotation + 180 : rotation - 180);
    setClockwise(!clockwise);
  };
  const performanceFee: number | undefined =
    deatils?.fundManagerFee +
    deatils?.protocolFee +
    (deatils?.partnerFee / deatils?.feeDecimals) * 100;

  const handleStakeClick = () => {
    switch (vaultAddress) {
      case "0x67e07BFfce318ADbA7b08618CBf4B8E271499197_40":
      case "0x4778CAAa0E52F0B58eAF5e947Ae81A0a10cDe707_40":
      case "0xA0dD02ef78570a4d93b7eE334EA9c593F7A0ebc4_40":
        window.location.href = "https://app.rivera.money/#/earn/telos";
        break;
      case "0x67e07BFfce318ADbA7b08618CBf4B8E271499197_137":
        window.location.href = "https://app.rivera.money/#/earn/polygon"; // Replace with the actual URL for this vaultAddress
        break;
      case "0xE45F416eE25844281edF2780247E28569303c7Cd_666666666":
      case "0x39dD79E8b1e74E8B514D7e133b3671435Ec3Da42_666666666":
      case "0xA95417805d18d00844c3C6FB7742577Cd263fE05_666666666":
        window.location.href = "https://app.rivera.money/#/earn/degen"; // Replace with the actual URL for this vaultAddress
        break;
      default:
        break;
    }
  };
  const getHref = (vaultAddress: any) => {
    switch (vaultAddress) {
      case "0x67e07BFfce318ADbA7b08618CBf4B8E271499197":
      case "0x4778CAAa0E52F0B58eAF5e947Ae81A0a10cDe707_40":
      case "0xA0dD02ef78570a4d93b7eE334EA9c593F7A0ebc4_40":
        return "https://app.rivera.money/#/earn/telos";
      case "0x67e07BFfce318ADbA7b08618CBf4B8E271499197_137":
        return "https://app.rivera.money/#/earn/polygon"; // Replace with the actual URL for this vaultAddress
      case "0xE45F416eE25844281edF2780247E28569303c7Cd_666666666":
      case "0x39dD79E8b1e74E8B514D7e133b3671435Ec3Da42_666666666":
      case "0xA95417805d18d00844c3C6FB7742577Cd263fE05_666666666":
        return "https://app.rivera.money/#/earn/degen"; // Replace with the actual URL for this vaultAddress

      default:
        return null;
    }
  };
  const href = getHref(vaultAddress);

  return (
    <>
      <Toast ref={toast} />
      {loading ? (
        <>
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        </>
      ) : (
        <>
          <div className="custom-container mt-4">
            <div className="row mob_clm_rvs">
              <div className="col-md-8">
                <div className="small-div-1"></div>
                <div className="first_section outer_section_detail notMobileDevice">
                  <div className="d_flex_frst">
                    <div className="header_font_size redHatFont">
                      <span>
                        <img
                          src={deatils?.assetImg}
                          alt="btc img"
                          className="btc_asst_img_width"
                        />
                      </span>
                      <span>
                        <img
                          src={deatils?.assetImg2}
                          alt="btc img"
                          className="btc_img_width"
                        />
                      </span>
                      {deatils?.vaultName}
                    </div>
                    <div className="chain_pos_det_v2">
                      <span>
                        <img src={deatils?.networkImg} alt="chain" />
                      </span>
                    </div>
                  </div>
                  <div className="dsp_flex_start dspWrap mt-3 mb-4">
                    {deatils?.tags.map((tag: string, index: number) => (
                      <div className="trdng_outer" key={index}>
                        <span className="trdng_width">
                          <img
                            src={getTagIcon(tag)}
                            className="ml_8"
                            alt={`${tag} img`}
                          />
                          {tag}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className="dsp mb-3 wdth_100">
                    <div className="mbl_mrgn_10">
                      APR
                      <br />
                      <div className="align-items-center d-flex">
                        <span className="holding_val">
                          {(deatils.lpApr + deatils.xirvApr).toFixed(2)}%
                        </span>
                        <img
                          className={`toolTipPerformance ml_5`}
                          src={infoCircleImg}
                          alt="info"
                        />
                        <Tooltip
                          target={`.toolTipPerformance`}
                          mouseTrack
                          mouseTrackLeft={10}
                        >
                          LP: {deatils.lpApr.toFixed(2)}%<br />
                          {deatils.xirvApr !== 0 && // Check if deatils.xirvApr is not zero
                            `xRIV: ${deatils.xirvApr.toFixed(2)}%`}
                        </Tooltip>
                      </div>
                    </div>

                    <div className="mbl_mrgn_10">
                      TVL <br />{" "}
                      <span className="fnt_wgt_600">
                        {fnum(Number(deatils.tvl))} {deatils?.assetName}
                      </span>
                    </div>
                    <div className="mbl_mrgn_10">
                      DEX <br />{" "}
                      <span>
                        <img
                          src={deatils?.poweredBy}
                          className="wdth_140"
                          alt="pancake"
                        />
                      </span>
                    </div>
                    <div>
                      LP Pool & Fee Tier <br />{" "}
                      <span className="fnt_wgt_600">
                        <span>
                          {deatils.vaultName.split("-").slice(0, 2).join("-")}{" "}
                        </span>
                        <span>
                          {" "}
                          (
                          {(Number(deatils.feeTier) / Math.pow(10, 4)).toFixed(
                            2
                          )}
                          %)
                        </span>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="second_section outer_section_detail nav_design ovrFlow">
                  <nav className="navigation">
                    <ul>
                      <li
                        id="li_overview"
                        onClick={() => scrollToSection(overview)}
                      >
                        Overview
                      </li>
                      <li
                        id="li_strategy"
                        onClick={() => scrollToSection(strategy)}
                      >
                        Strategy
                      </li>
                      <li
                        id="li_yieldToken"
                        onClick={() => scrollToSection(yieldToken)}
                      >
                        Yield Token
                      </li>
                      <li id="li_risk" onClick={() => scrollToSection(risk)}>
                        Risk
                      </li>
                      <li
                        id="li_portfolioManager"
                        onClick={() => scrollToSection(portfolioManager)}
                      >
                        Portfolio Manager
                      </li>
                      <li id="li_fees" onClick={() => scrollToSection(fees)}>
                        Fees
                      </li>
                      <li id="li_fAQ" onClick={() => scrollToSection(fAQ)}>
                        FAQ
                      </li>
                    </ul>
                  </nav>
                </div>
                <section id="overview" ref={overview}>
                  <div className="third_section outer_section_detail">
                    <div className="hdr_txt mb-3 redHatFont">Overview</div>
                    <div className="dsp_cont">
                      <div className="brdr_blck pdng_box mb-2">
                        TVL{" "}
                        <div className="mt-2 fnt_wgt_600">
                          {fnum(Number(deatils.tvl))} {deatils?.assetName}
                        </div>
                      </div>
                      <div className="brdr_blck pdng_box mb-2">
                        APR{" "}
                        <div className="mt-2 txt_clr_grn">
                          {isDataLoadng ? (
                            <>
                              <Skeleton
                                width="5rem"
                                className="mb-2"
                              ></Skeleton>
                            </>
                          ) : (
                            <>
                              <div className="align-items-center d-flex">
                                <span className="holding_val">
                                  {(deatils.lpApr + deatils.xirvApr).toFixed(2)}
                                  %
                                </span>
                                <img
                                  className={`toolTipPerformance ml_5`}
                                  src={infoCircleImg}
                                  alt="info"
                                />
                                <Tooltip
                                  className=""
                                  target={`.toolTipPerformance`}
                                  mouseTrack
                                  mouseTrackLeft={10}
                                >
                                  LP: {deatils.lpApr.toFixed(2)}%<br />
                                  {deatils.xirvApr !== 0 && // Check if deatils.xirvApr is not zero
                                    `xRIV: ${deatils.xirvApr.toFixed(2)}%`}
                                </Tooltip>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="brdr_blck pdng_box mb-2">
                        Denomination Asset{" "}
                        <div className="mt-2 fnt_wgt_600">
                          {" "}
                          <img
                            src={deatils.denoImage}
                            alt="btc img"
                            className="wdth_28"
                          />{" "}
                          {deatils.assetName}
                        </div>
                      </div>

                      <div className="brdr_blck pdng_box mb-2">
                        Vault Contract{" "}
                        <div className="mt-2 fnt_wgt_600">
                          {isDataLoadng ? (
                            <>
                              <Skeleton
                                width="5rem"
                                className="mb-2"
                              ></Skeleton>
                            </>
                          ) : (
                            <>
                              <span>
                                <a
                                  href={`${deatils?.explorerLink}address/${deatils?.vaultAddress}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  View on Explorer
                                </a>
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section id="strategy" ref={strategy}>
                  <div className="third_section outer_section_detail">
                    <div className="hdr_txt mb-2 redHatFont">Strategy</div>
                    <div className="fnt_wgt_600 mb-2 redHatFont">Summary</div>
                    <span>
                      <ul>
                        <li>{deatils?.description}</li>
                      </ul>
                    </span>

                    <>
                      <div className="backGrd mb-3 ">
                        <div className="dsp mb-2">
                          <div className="fnt_wgt_600 mb-3">
                            <img
                              src={deatils?.poweredBy}
                              className="wdth_140"
                              alt="panake"
                            />
                          </div>
                          <div className="d-flex mb-3">
                            <div
                              className="crsr_pntr"
                              onClick={() => {
                                goToUrl(deatils?.website);
                              }}
                            >
                              <span className="westBtn hover101">
                                <img src={globeImg} alt="website" /> Website
                              </span>
                            </div>
                            <div
                              className="crsr_pntr"
                              onClick={() => {
                                // window.location.href = `${deatils?.explorerLink}address/${deatils?.stake}`;
                                goToUrl(
                                  `${deatils?.explorerLink}address/${deatils?.stake}`
                                );
                              }}
                            >
                              <span className="westBtn hover101">
                                <img
                                  src={arrowUpImg}
                                  alt="website"
                                  className=""
                                />{" "}
                                Contract
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="fnt_14 mt-3">{deatils?.detailsNew}</div>
                      </div>
                    </>

                    {/* {valutJsonData?.protocols.map((data: any) => {
                      return (
                        <>
                          <div className="backGrd mb-3 ">
                            <div className="dsp mb-2">
                              <div className="fnt_wgt_600 mb-3">
                                <img
                                  src={data?.poweredBy}
                                  className="wdth_140"
                                  alt="panake"
                                />
                              </div>
                              <div className="d-flex mb-3">
                                <div
                                  className="crsr_pntr"
                                  onClick={() => {
                                    goToUrl(data.website);
                                  }}
                                >
                                  <span className="westBtn hover101">
                                    <img src={globeImg} alt="website" /> Website
                                  </span>
                                </div>
                                <div
                                  className="crsr_pntr"
                                  onClick={() => {
                                    goToUrl(data.contract);
                                  }}
                                >
                                  <span className="westBtn hover101">
                                    <img
                                      src={arrowUpImg}
                                      alt="website"
                                      className=""
                                    />{" "}
                                    Contract
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="fnt_14 mt-3">{data.details}</div>
                          </div>
                        </>
                      );
                    })} */}
                    <div className="dsp2Comp mb-2 mt-3">
                      <div className="mtMob wdth_30">
                        <div>Current Price</div>
                        <div className="fnt_wgt_600 redHatFont">
                          {values.newcurrentPrice.toFixed(4)}{" "}
                          {values.deatils.asset2.toLocaleUpperCase()}/
                          {values.deatils.asset1.toLocaleUpperCase()}{" "}
                        </div>
                      </div>
                      <div className="mtMob wdth_60 mlBanner">
                        <div>Selected Range</div>
                        <div className="fnt_wgt_600 redHatFont">
                          {values.lowerRangePrice.toFixed(4)} -{" "}
                          {values.upperRangePrice.toFixed(4)}{" "}
                          {values.deatils.asset2.toLocaleUpperCase()}/
                          {values.deatils.asset1.toLocaleUpperCase()}
                        </div>
                      </div>

                      <div className="mt-1">
                        <div>Token Allocation</div>
                        <div className="fnt_wgt_600 redHatFont capOn">
                          {values.token0Allocation.toFixed(0)}%{" "}
                          {values.deatils.asset1} +{" "}
                          {values.token1Allocation.toFixed(0)}%{" "}
                          {values.deatils.asset2}
                        </div>
                      </div>
                      <img
                        src={switchImage}
                        alt="switch"
                        onClick={toggleValues}
                        className="toggle-button crsr_pntr"
                        style={{
                          transform: `rotate(${rotation}deg)`,
                          transition: "transform 0.5s",
                        }}
                      />
                    </div>
                    <div className="mt-1">
                      <div className="hdr_tx mt-2 fnt_wgt_60Big">
                        Pool Comparison
                      </div>
                      <div className="inStick mt-1">In Range</div>
                      <div className="mt-05">
                        <ProgressComparison
                          productAProgress={values.token0Allocation}
                          productBProgress={values.token1Allocation}
                        />
                      </div>
                      <div className="containerde">
                        <div>
                          <div className="item d-flex align-items-center">
                            <img
                              className="tokenImg"
                              src={values.deatils.assetImg}
                              alt={values.deatils.asset1}
                            />
                            <div className="fnt_wgt_800 redHatFont">
                              {values.deatils.asset1}
                            </div>
                            <div className="redhatFont">
                              {values.token0Allocation.toFixed(0)}%
                            </div>
                          </div>
                          <div className=" fnt_wgt_500 ml-2">
                            $ {values.deatils.tvlInusd1}
                          </div>
                        </div>
                        <div>
                          <div className="item d-flex align-items-center">
                            <img
                              className="tokenImg"
                              src={values.deatils.assetImg2}
                              alt={values.deatils.asset2}
                            />
                            <div className="fnt_wgt_800 redHatFont">
                              {values.deatils.asset2}
                            </div>
                            <div className="redhatFont">
                              {values.token1Allocation.toFixed(0)}%
                            </div>
                          </div>
                          <div className="fnt_wgt_500 ml-2">
                            $ {values.deatils.tvlInusd2}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section id="yieldToken" ref={yieldToken}>
                  <div className="third_section outer_section_detail">
                    <div className="hdr_txt mb-2 redHatFont">Yield Token</div>

                    <div className="dsp2 align-items-center  mb-2 mt-3">
                      <div>
                        <div>Vault Name</div>
                        <div className="fnt_wgt_600 redHatFont">
                          {deatils?.vaultName}
                        </div>
                      </div>
                      <div className="">
                        <div>Token Symbol</div>
                        <div className="fnt_wgt_600 redHatFont">
                          {" "}
                          {deatils?.symbol}
                        </div>
                      </div>
                      <div className="mtMob">
                        <div>Current Supply</div>
                        <div className="fnt_wgt_600 redHatFont">
                          {deatils &&
                            `${(
                              deatils.totalSupply /
                              Math.pow(10, deatils.denominationDecimal)
                            ).toFixed(2)}`}{" "}
                          {deatils.symbol}
                        </div>
                      </div>
                    </div>
                    <div className="dsp2 align-items-center  mb-2 mt-3">
                      <div className="">
                        <div>Price</div>
                        <div className="fnt_wgt_600 redHatFont">
                          {(Number(userPrice) * Math.pow(10, 15)).toFixed(4)}{" "}
                          {deatils?.assetName}
                        </div>
                      </div>

                      <div>
                        <div className="mltracker">Token Tracker</div>
                        <div className="fnt_wgt_600 redHatFont">
                          <span className="vOEX">
                            <a
                              href={`${deatils?.explorerLink}address/${deatils?.vaultAddress}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              View on Explorer
                            </a>
                          </span>
                        </div>
                      </div>
                      <div className="notMobileDevice">
                        <div className="ft_content "></div>
                        <div className="fnt_wgt_600 redHatFont wdth_101"></div>
                      </div>
                    </div>
                  </div>
                </section>

                <section id="risk" ref={risk}>
                  <Risk />
                </section>
                <section id="portfolioManager" ref={portfolioManager}>
                  <div className="fifth_section outer_section_detail">
                    <div className="hdr_txt mb-2 redHatFont">
                      Portfolio Manager
                    </div>
                    <div className="mt-4">
                      <span className="fnt_wgt_600">Vault owner</span>
                      <div className="dsp portfolio_wdth_55 prtfol_back mt-2 mb-3">
                        <div className="fnt_14 overflowWrap">
                          {deatils?.vaultOwner}
                          <span className="ml_10">
                            <a
                              href={`${deatils?.explorerLink}address/${deatils?.vaultOwner}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={eternalLinkImg}
                                alt="external link img"
                              />
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <span className="fnt_wgt_600">Fund Manager</span>
                      <div className="dsp portfolio_wdth_55 prtfol_back mt-2 mb-3">
                        <div className="fnt_14 overflowWrap">
                          {deatils?.fundManger}
                          <span className="ml_10">
                            <a
                              href={`${deatils?.explorerLink}address/${deatils?.fundManger}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={eternalLinkImg}
                                alt="external link img"
                              />
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section id="fees" ref={fees}>
                  <div className="sixth_section outer_section_detail">
                    <div className="hdr_txt mb-2 redHatFont">Fees</div>
                    <div>
                      <table className="table_degn">
                        <thead>
                          <tr className="imDark ">
                            <th scope="col">Fee type</th>
                            <th scope="col">Rate</th>
                            <th scope="col">Recipient</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="imDark">
                            <td className="w-fit">Performance Fee</td>
                            <td className="flexT">
                              <div className="flexT"> {performanceFee}%</div>
                            </td>
                            <td>
                              Protocol & Fund manager
                              <br />
                            </td>
                          </tr>

                          <tr className="imDark">
                            <td>Exit Fee</td>

                            <td className="flexT">
                              <div>
                                <div>
                                  {" "}
                                  {(deatils.withdrawFee /
                                    deatils.withdrawFeeDecimals) *
                                    100}
                                  %
                                </div>
                              </div>
                            </td>
                            <td>
                              Protocol
                              <br />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
                <section id="fAQ" ref={fAQ}>
                  <CommonFAQ />
                </section>
              </div>
              <div className="col-md-4">
                <div className="small-div-2"></div>

                <div className="first_section outer_section_detail mobileDevice">
                  <div className="d_flex_frst">
                    <div className="header_font_size redHatFont">
                      <span>
                        <img
                          src={deatils?.assetImg}
                          alt="btc img"
                          className="btc_asst_img_width"
                        />
                      </span>
                      <span>
                        <img
                          src={deatils?.assetImg2}
                          alt="btc img"
                          className="btc_img_width"
                        />
                      </span>
                      {deatils?.vaultName}
                    </div>
                    <div className="chain_pos_det_v2">
                      <span>
                        <img src={deatils?.networkImg} alt="chain" />
                      </span>
                    </div>
                  </div>
                  <div className="dsp_flex_start dspWrap mt-3 mb-4">
                    {valutJsonData?.isLiquidityMining ? (
                      <>
                        <div className="trdng_outer">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={almImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            Liquidity Mining
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {valutJsonData?.isActiveStrategy ? (
                      <>
                        <div className="trdng_outer mr_12">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={almImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            Active Strategy
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {valutJsonData?.isWideRange ? (
                      <>
                        <div className="trdng_outer mr_12">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={almImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            Wide Range
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {valutJsonData?.isPeggedStrategy ? (
                      <>
                        <div className="trdng_outer mr_12">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={dynamicStrategyImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            Pegged Strategy
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {valutJsonData?.isStaticRange ? (
                      <>
                        <div className="trdng_outer mr_12">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={dynamicStrategyImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            Static Range
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {valutJsonData?.isDynamicStrategy ? (
                      <>
                        <div className="trdng_outer">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={dynamicStrategyImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            Dynamic
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {valutJsonData?.isLSDFarming ? (
                      <>
                        <div className="trdng_outer">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={LSDFarmingImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            LSD Farming
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {valutJsonData?.isVolatilePair ? (
                      <>
                        {" "}
                        <div className="trdng_outer">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={VolatilePairColorImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            Volatile Pair
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {valutJsonData?.isStablePair ? (
                      <>
                        <div className="trdng_outer">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={StablePairColorImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            Stable Pair
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="dsp mb-3 wdth_100">
                    <div className="mbl_mrgn_10">
                      APR
                      <br />
                      <div className="align-items-center d-flex">
                        <span className="holding_val">
                          {(deatils.lpApr + deatils.xirvApr).toFixed(2)}%
                        </span>
                        <img
                          className={`toolTipPerformance ml_5`}
                          src={infoCircleImg}
                          alt="info"
                        />
                        <Tooltip
                          className=""
                          target={`.toolTipPerformance`}
                          mouseTrack
                          mouseTrackLeft={10}
                        >
                          LP: {deatils.lpApr.toFixed(2)}%<br />
                          {deatils.xirvApr !== 0 && // Check if deatils.xirvApr is not zero
                            `xRIV: ${deatils.xirvApr.toFixed(2)}%`}
                        </Tooltip>
                      </div>
                    </div>
                    <div className="mbl_mrgn_10">
                      TVL <br />{" "}
                      <span className="fnt_wgt_600">
                        {fnum(Number(deatils.tvl))} {deatils?.assetName}
                      </span>
                    </div>
                    <div className="mbl_mrgn_10">
                      DEX <br />{" "}
                      <span>
                        <img
                          src={deatils?.poweredBy}
                          className="wdth_140"
                          alt="pancake"
                        />
                      </span>
                    </div>
                    <div>
                      LP Pool & Fee Tier <br />{" "}
                      <span className="fnt_wgt_600">
                        <span>{deatils.vaultName}</span>
                        <span>
                          (
                          {(Number(deatils.feeTier) / Math.pow(10, 4)).toFixed(
                            2
                          )}
                          %)
                        </span>
                      </span>
                    </div>
                  </div>
                </div>

                {address ? (
                  <>
                    <div className="first_section outer_section_detail">
                      <div className="dsp">
                        <div>
                          <div className="holding_header">
                            Your Holdings
                            <img
                              className="toolTipHolding ml_5"
                              src={infoCircleImg}
                              data-pr-tooltip="The displayed portfolio balance has already accounted for the vault performance fee."
                              alt="info"
                            />
                            <Tooltip
                              target=".toolTipHolding"
                              mouseTrack
                              mouseTrackLeft={10}
                            />
                          </div>
                          <div className="holding_header_inner mb-3 secondary_color">
                            {Number(userShare).toFixed(4)} {deatils?.assetName}
                          </div>
                        </div>
                        <div className="txtAlgnRight">
                          <img
                            src={dollarImg}
                            className="dllrImgwdth"
                            alt="dollar"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="second_section outer_section_detail pos_sticky">
                  <TabView>
                    <TabPanel
                      header={
                        <CustomTabHeader icon={depositTabImg} title="Deposit" />
                      }
                    >
                      <div className="mt-3">
                        <div className="dsp">
                          <div>Deposits</div>
                          <div>
                            {fnum(Number(deatils.tvl))} {deatils?.assetName}
                          </div>
                        </div>
                        <div>
                          <ProgressBar
                            showValue={false}
                            value={Number(
                              (
                                (Number(deatils.tvlInusd) /
                                  Number(tvlCapInUsd)) *
                                100
                              ).toFixed(4)
                            )}
                          ></ProgressBar>
                        </div>
                        <div className="dsp mb-3">
                          <div>Capacity</div>
                          <div>
                            {fnum(Number(tvlCap))} {deatils?.assetName}
                          </div>
                        </div>
                        <div className="dsp backGrd mb-3">
                          <div className="fnt_wgt_600 text-center">
                            <img
                              src={deatils?.denoImage}
                              className="wdth_30_px"
                              alt="usdt"
                            />{" "}
                            <br /> {deatils?.assetName}
                          </div>
                          <div>
                            <input
                              className="input_box_details"
                              type="number"
                              id="first_name"
                              name="first_name"
                              value={depositAmout}
                              onChange={handledepositAmoutChange}
                            />
                            <button
                              className="btn mxBtn my-div"
                              onClick={updateDepositMax}
                            >
                              Max
                            </button>
                          </div>
                        </div>
                        <div className="dsp">
                          <div>Wallet balance</div>
                          <div>
                            {Number(walletBalance).toFixed(2)}{" "}
                            {deatils?.assetName}
                          </div>
                        </div>
                        <div className="dsp">
                          <div className="buy_cake mt-1 mb-2">
                            {" "}
                            <a
                              target="_blank"
                              href={deatils?.buyToken}
                              className="clr_prpl"
                              rel="noreferrer"
                            >
                              Buy ${deatils?.assetName}
                            </a>
                          </div>
                        </div>

                        <div className="mt-3 text-center">
                          {isConnected ? (
                            (chain as any)?.id !== Number(deatils?.chainId) ? (
                              <button
                                className="btn btn-riv-primary wdth_100"
                                onClick={() => {
                                  networkSwitchHandler(
                                    Number(deatils?.chainId)
                                  );
                                }}
                              >
                                Switch to {deatils?.chainName}
                              </button>
                            ) : isApproved ? (
                              <button
                                className="btn btn-riv-primary wdth_100"
                                onClick={() => {
                                  deposit();
                                }}
                                disabled={isTransactionOnGoing}
                              >
                                Deposit
                              </button>
                            ) : (
                              <button
                                className="btn btn-riv-primary wdth_100"
                                onClick={approveIntilize}
                                disabled={isTransactionOnGoing}
                              >
                                Approve
                              </button>
                            )
                          ) : (
                            <>
                              <div className="wdth_100">
                                <ConnectBtn />
                              </div>
                            </>
                          )}
                        </div>
                        <div className="mt-3 text-center">
                          <div>
                            <img src={zapIMg} alt="dollar" />
                            <span>Zap in with ${deatils?.assetName}</span>
                          </div>
                        </div>
                        <div className="mt-3 text-center">
                          {isConnected &&
                            (vaultAddress ===
                              "0x67e07BFfce318ADbA7b08618CBf4B8E271499197_40" ||
                              vaultAddress ===
                                "0x4778CAAa0E52F0B58eAF5e947Ae81A0a10cDe707_40" ||
                              vaultAddress ===
                                "0xA0dD02ef78570a4d93b7eE334EA9c593F7A0ebc4_40" ||
                              vaultAddress ===
                                "0x67e07BFfce318ADbA7b08618CBf4B8E271499197_137" ||
                              vaultAddress ===
                                "0xA95417805d18d00844c3C6FB7742577Cd263fE05_666666666" ||
                              vaultAddress ===
                                "0x39dD79E8b1e74E8B514D7e133b3671435Ec3Da42_666666666" ||
                              vaultAddress ===
                                "0xE45F416eE25844281edF2780247E28569303c7Cd_666666666") && (
                              <button
                                className="btn btn-riv-primary wdth_100"
                                onClick={handleStakeClick}
                              >
                                Stake
                              </button>
                            )}
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel
                      header={
                        <CustomTabHeader
                          icon={withdrwaTabImg}
                          title="Withdraw"
                        />
                      }
                    >
                      <div className="mt-3">
                        <div className="dsp">
                          <div>Deposits</div>
                          <div>
                            {fnum(Number(deatils.tvl))} {deatils?.assetName}
                          </div>
                        </div>
                        <div>
                          <ProgressBar
                            showValue={false}
                            value={Number(
                              (
                                (Number(deatils.tvlInusd) /
                                  Number(tvlCapInUsd)) *
                                100
                              ).toFixed(4)
                            )}
                          ></ProgressBar>
                        </div>
                        <div className="dsp mb-3">
                          <div>Capacity</div>
                          <div>
                            {fnum(Number(tvlCap))} {deatils?.assetName}
                          </div>
                        </div>
                        <div className="dsp backGrd mb-3">
                          <div className="fnt_wgt_600 text-center">
                            <img
                              src={deatils?.denoImage}
                              className="wdth_30_px"
                              alt="usdt"
                            />{" "}
                            <br /> {deatils?.assetName}
                          </div>
                          <div>
                            <input
                              className="input_box_details"
                              type="number"
                              id="first_name_2"
                              name="first_name_2"
                              value={withdrawAmout}
                              onChange={handlewithdrawAmoutChange}
                            />
                            <button
                              className="btn mxBtn my-div"
                              onClick={updateWithdrawMax}
                            >
                              Max
                            </button>
                          </div>
                        </div>
                        <div className="dsp">
                          <div>Wallet balance</div>
                          <div>
                            {Number(walletBalance).toFixed(2)}{" "}
                            {deatils?.assetName}
                          </div>
                        </div>
                        <div className="dsp">
                          <div className="buy_cake mt-1 mb-2">
                            {" "}
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={deatils?.buyToken}
                              className="clr_prpl"
                            >
                              Buy ${deatils?.assetName}
                            </a>
                          </div>
                        </div>
                        <div className="mt-3 text-center">
                          {isConnected ? (
                            (chain as any)?.id !== Number(deatils?.chainId) ? (
                              <button
                                className="btn btn-riv-primary wdth_100"
                                onClick={() => {
                                  networkSwitchHandler(
                                    Number(deatils?.chainId)
                                  );
                                }}
                              >
                                Switch to {deatils?.chainName}
                              </button>
                            ) : (
                              <button
                                className="btn btn-riv-primary wdth_100"
                                disabled={isTransactionOnGoing}
                                onClick={withdraw}
                              >
                                Withdraw
                              </button>
                            )
                          ) : (
                            <>
                              <div className="wdth_100">
                                <ConnectBtn />
                              </div>
                            </>
                          )}
                        </div>
                        <div className="mt-3 text-center">
                          <div>
                            <img src={zapIMg} alt="dollar" />
                            <span>Zap out with ${deatils?.assetName}</span>
                          </div>
                        </div>
                        <div className="mt-3 text-center">
                          {isConnected &&
                            (vaultAddress ===
                              "0x67e07BFfce318ADbA7b08618CBf4B8E271499197_40" ||
                              vaultAddress ===
                                "0x4778CAAa0E52F0B58eAF5e947Ae81A0a10cDe707_40" ||
                              vaultAddress ===
                                "0xA0dD02ef78570a4d93b7eE334EA9c593F7A0ebc4_40" ||
                              vaultAddress ===
                                "0x67e07BFfce318ADbA7b08618CBf4B8E271499197_137" ||
                              vaultAddress ===
                                "0xA95417805d18d00844c3C6FB7742577Cd263fE05_666666666" ||
                              vaultAddress ===
                                "0x39dD79E8b1e74E8B514D7e133b3671435Ec3Da42_666666666" ||
                              vaultAddress ===
                                "0xE45F416eE25844281edF2780247E28569303c7Cd_666666666") && (
                              <button
                                className="btn btn-riv-primary wdth_100"
                                onClick={handleStakeClick}
                              >
                                Stake
                              </button>
                            )}
                        </div>
                      </div>
                    </TabPanel>
                  </TabView>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <Dialog
        visible={approvalWaitingVisible}
        className="dialogWdth"
        onHide={() => setapprovalWaitingVisible(false)}
      >
        <div className="text-center">
          <img src={waitingImg} alt="chain" className="gif_wdth" />
          <div className="header_font_size">Waiting for Approval</div>
          <div>Transaction is pending on blockchain.</div>
        </div>
      </Dialog>
      <Dialog
        visible={approvalSuccessfullVisible}
        className="dialogWdth"
        onHide={() => setapprovalSuccessfullVisible(false)}
      >
        <div className="text-center">
          <img src={waitingImg} alt="chain" className="gif_success_wdth" />
          <div className="header_font_size">Approval Successful</div>
          <div>
            Opening Metamask, please confirm transaction in your wallet.
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={openMetamaskVisible}
        className="dialogWdth"
        onHide={() => setOpenMetamaskVisible(false)}
      >
        <div className="text-center">
          <img src={successfullImg} alt="chain" className="gif_success_wdth" />
          <div className="header_font_size">Opening Metamask</div>
          <div>Please confirm transaction in your wallet.</div>
        </div>
      </Dialog>

      <Dialog
        visible={depositWaitingVisible}
        className="dialogWdth"
        onHide={() => setdepositWaitingVisible(false)}
      >
        <div className="text-center">
          <img src={waitingImg} alt="chain" className="gif_wdth" />
          <div className="header_font_size">Waiting for {transactionType}</div>
          <div>Transaction is pending on blockchain.</div>
        </div>
      </Dialog>

      <Dialog
        visible={depositSuccessfullVisible}
        className="dialogWdth"
        onHide={() => setdepositSuccessfullVisible(false)}
      >
        <div className="text-center">
          <img src={successfullImg} alt="chain" className="gif_success_wdth" />
          <div className="header_font_size">{transactionType} Successful</div>
          {transactionType === "Withdraw" ? (
            <></>
          ) : (
            <div>Great! You are invested now.</div>
          )}
        </div>
      </Dialog>
    </>
  );
}
