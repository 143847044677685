import React, { useState, useEffect } from 'react';
import stars from "../../assets/images/stars.svg"

const Banner: React.FC = () => {
    const [showBanner, setShowBanner] = useState<boolean>(true);

    useEffect(() => {
        const isBannerClosed = localStorage.getItem('isBannerClosed');
        if (isBannerClosed === 'true') {
            setShowBanner(false);
        }
    }, []);

    const handleCloseBanner = () => {
        setShowBanner(false);
    };

    return (
        <>
            {showBanner && (
                <>
                    <div className='bg-bluegray-900'>
                        <div className="flexBanner">
                            <div className='mobile-banner-text redHatFont fnt_wgt_500 flexT'>

                                <div>
                                    <img src={stars} alt='star' className='mr-1' />
                                    {/* <img src={stars} alt='star' /> */}
                                    <span className='whitespace-nowrap bckgrnd_img_card_fnt mobile-banner-text text-wrap'>
                                        Rivera Pre-Mine program is Live Now.

                                        <span className='ml-05'>
                                            <a href="https://medium.com/rivera-money/rivera-voyage-kicked-off-with-pre-mined-riv-tokens-2073687d5c0b"
                                                target='_blank' rel="noreferrer" className='bckgrnd_img_card_fnt whitespace-nowrap custom-link'>Learn More</a>
                                            <span className='crsr_pntr mobileDevice p-1 fnt_wgt_800 txtBlack' onClick={handleCloseBanner}>&times;</span>
                                            <img src={stars} alt='star' className='notMobileDevice' />
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="close-icon notMobileDevice" onClick={handleCloseBanner}>
                            <span className='crsr_pntr fnt_wgt_800 txtBlack'>&times;</span>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Banner;
