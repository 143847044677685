import { ethers } from "ethers";
import { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useAccount, useSwitchNetwork, useNetwork } from "wagmi";
import { backendApiUrlBase } from "../../constants/global";
import ManageBtn from "../../component/manageBtn";
import ConnectBtn from "../../component/ConnectBtn";
import coins from "../../assets/images/manageImage.png";
import masterChef from "../../abi/out/MasterChefNew.sol/MasterChefNew.json";
import riveraAutoCompoundingVaultV2WhitelistedJson from "../../abi/out/RiveraAutoCompoundingVaultV2Whitelisted.sol/RiveraAutoCompoundingVaultV2Whitelisted.json";
import ClaimButton from "../../component/ClaimButton";
import Web3 from "web3";
import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";
import arrowUpImg from "../../assets/images/arrow-up-right.png";

interface VaultItem {
  _id: string;
  symbol: string;
  displayName: string;
  strategyType: string;
  name: string;
  chain?: number;
  masterChef: string | null;
  vaultAddress: string;
  denominationAssetAddress: string;
  chainRPC: string;
  totalAssets: string;
  totalSupply: string;
  denominationDecimal: number;
  apr: {
    apr: number;
  } | null;
  totalStaked?: number;
  totalStakedDollar?: number;
  myBalance?: number;
  myBalanceNonDollar?: number;
  myStake?: number;
  myStakeDollar?: number;
  totalExtraPerBlock?: number;
  totalSlushPerBlock?: number;
  mywtlosEarning?: number;
  myslushEarning?: number;
}

function Manage() {
  // const address = "0x6C47DCbE1985B717488a2aA6Aeed209618d93c5E";
  const { chain } = useNetwork();
  const [vaults, setVaults] = useState<VaultItem[]>([]);
  const { address, isConnected } = useAccount();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [diff, setDiff] = useState(0);
  const [walletBalance, setWalletBalance] = useState<any>(0);
  const toast = useRef<Toast>(null);
  const addDDD = "0xC7c74fB5aa1b11d2e960B6cf9C057F67c8C602bc";
  const [vaultNumberMyStake, setVaultNumberMyStake] = useState<
    number | undefined
  >();
  const [vaultNumberTotalStaked, setVaultNumberTotalStaked] = useState<
    number | undefined
  >();
  const [vaultNumberBalance, setVaultNumberBalance] = useState<
    number | undefined
  >();

  const showSuccess = (message: string) => {
    toast.current?.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  };

  const showWarn = (header: string, message: string) => {
    toast.current?.show({
      severity: "warn",
      summary: header,
      detail: message,
      life: 3000,
    });
  };

  const showError = (message: string) => {
    toast.current?.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };
  const getTwoDecimal = (num: any) => {
    const roundedNum = Math.floor(num * 100) / 100;
    return roundedNum.toFixed(2);
  };
  const fnum = (x: any) => {
    if (isNaN(x)) return x;

    if (x < 999) {
      return getTwoDecimal(x);
    }

    if (x < 1000) {
      return getTwoDecimal(x);
    }

    if (x < 10000) {
      return getTwoDecimal(x / 1000) + "K";
    }

    if (x < 100000) {
      return getTwoDecimal(x / 1000) + "K";
    }

    if (x < 1000000) {
      return getTwoDecimal(x / 1000) + "K";
    }

    if (x < 10000000) {
      return getTwoDecimal(x / 1000000) + "M";
    }

    if (x < 1000000000) {
      return getTwoDecimal(x / 1000000) + "M";
    }

    if (x < 1000000000000) {
      return getTwoDecimal(x / 1000000000) + "B";
    }

    return "1T+";
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsDataLoading(true);
        const response = await fetch(`${backendApiUrlBase}vaults`);
        const data = await response.json();
        const filteredVaults = data.filter(
          (vault: any) =>
            vault.masterChef === "0xC7c74fB5aa1b11d2e960B6cf9C057F67c8C602bc"
        );
        setVaults(filteredVaults);

        const provider = new ethers.providers.JsonRpcProvider(
          "https://mainnet.telos.net/evm"
        );
        const response2 = await fetch(
          "https://api.rivera.money/oracle/coingecko"
        );
        const data2 = await response2.json();
        let priceData: any = null;
        let priceUSD1: number | null = null;
        let priceUSD2: number | null = null;
        let priceUSD3: number | null = null;
        priceData = data2.find(
          (item: any) =>
            item.denominationAssetAddress ===
            "0x975ed13fa16857e83e7c493c7741d556eaad4a3f"
        );
        priceUSD1 = priceData ? Number(priceData.priceUSD) : null;
        priceData = data2.find(
          (item: any) =>
            item.denominationAssetAddress ===
            "0xd102ce6a4db07d247fcc28f366a623df0938ca9e"
        );
        priceUSD2 = priceData ? Number(priceData.priceUSD) : null;
        priceData = data2.find(
          (item: any) =>
            item.denominationAssetAddress ===
            "0xac45ede2098bc989dfe0798b4630872006e24c3f"
        );
        priceUSD3 = priceData ? Number(priceData.priceUSD) : null;

        const updatedVaults = await Promise.all(
          filteredVaults.map(async (vault: any) => {
            const vaultContractERC = new ethers.Contract(
              vault.vaultAddress,
              riveraAutoCompoundingVaultV2WhitelistedJson.abi,
              provider
            );
            let assetConversion = vault.totalAssets / vault.totalSupply;
            const vaultContract = new ethers.Contract(
              "0xC7c74fB5aa1b11d2e960B6cf9C057F67c8C602bc",
              masterChef.abi,
              provider
            );
            // Get conversion factor
            const share = await vaultContractERC.balanceOf(
              "0xC7c74fB5aa1b11d2e960B6cf9C057F67c8C602bc"
            );
            const vaultNumbers: { [key: string]: number } = {
              "RIV-01-01-Z": 0,
              "RIV-01-03-Y": 1,
              "RIV-01-04-Y": 2,
            };
            // total stake
            let denonimationDecimal2 = 0;
            if (vaultNumbers[vault.symbol] === 1) {
              denonimationDecimal2 = 6;
            } else {
              denonimationDecimal2 = 18;
            }
            const totalStaked = Number(
              share / Math.pow(10, denonimationDecimal2)
            ).toFixed(2);
            let totalStakedDollar = assetConversion * share;
            totalStakedDollar =
              totalStakedDollar / Math.pow(10, denonimationDecimal2);
            let priceUSD = 0;
            if (vaultNumbers[vault.symbol] === 1) {
              priceUSD = priceUSD1 !== null ? priceUSD1 : 1;
            } else {
              priceUSD = priceUSD2 !== null ? priceUSD2 : 1;
            }
            totalStakedDollar = priceUSD * totalStakedDollar;

            totalStakedDollar = Number(totalStakedDollar.toFixed(2));

            let denonimationDecimal = 0;
            if (vaultNumbers[vault.symbol] === 1) {
              denonimationDecimal = 6;
            } else {
              denonimationDecimal = 18;
            }
            let userBalance = await vaultContractERC.balanceOf(address);
            const userBalaceNonDollar = ethers.utils.formatUnits(
              userBalance,
              denonimationDecimal
            );

            userBalance = assetConversion * userBalance;
            userBalance = userBalance / Math.pow(10, denonimationDecimal);

            userBalance = Number(userBalance).toFixed(2);
            userBalance = priceUSD * userBalance;
            userBalance = Number(userBalance).toFixed(2);
            // my stake
            const userInfo = await vaultContract.userInfo(
              vaultNumbers[vault.symbol],
              address
            );
            const pendingExtra = await vaultContract.pendingExtra(
              vaultNumbers[vault.symbol],
              address
            );
            const pendingSlushs = await vaultContract.pendingSlushs(
              vaultNumbers[vault.symbol],
              address
            );
            const myStake = Number(
              ethers.utils.formatUnits(userInfo.amount, denonimationDecimal)
            );

            const mywtlosEarning = Number(
              ethers.utils.formatUnits(pendingExtra, 18)
            ).toFixed(2);

            const myslushEarning = Number(
              ethers.utils.formatUnits(pendingSlushs, 18)
            ).toFixed(2);

            let myStakeDollar = assetConversion * userInfo.amount;
            myStakeDollar = myStakeDollar / Math.pow(10, denonimationDecimal);

            myStakeDollar = myStakeDollar * priceUSD;
            myStakeDollar = Number(myStakeDollar.toFixed(2));

            // APR
            let totalExtraPerBlock = Number(
              await vaultContract.totalExtraPerBlock()
            );
            let totalSlushPerBlock = Number(
              await vaultContract.totalSlushsPerBlock()
            );
            let multiplicationRatio =
              vaultNumbers[vault.symbol] === 0 ? 3 / 5 : 1 / 5;

            if (priceUSD3 !== null) {
              totalSlushPerBlock =
                ((totalSlushPerBlock / Math.pow(10, 18)) *
                  multiplicationRatio *
                  2 *
                  3600 *
                  24 *
                  365 *
                  priceUSD3 *
                  100) /
                totalStakedDollar;
            } else {
              totalSlushPerBlock =
                ((totalSlushPerBlock / Math.pow(10, 18)) *
                  multiplicationRatio *
                  2 *
                  3600 *
                  24 *
                  365 *
                  1 *
                  100) /
                totalStakedDollar;
            }
            if (priceUSD2 !== null) {
              totalExtraPerBlock =
                ((totalExtraPerBlock / 10 ** 18) *
                  2 *
                  multiplicationRatio *
                  3600 *
                  24 *
                  365 *
                  priceUSD2 *
                  100) /
                totalStakedDollar;
            } else {
              totalExtraPerBlock =
                ((totalExtraPerBlock / 10 ** 18) *
                  2 *
                  multiplicationRatio *
                  3600 *
                  24 *
                  365 *
                  1 *
                  100) /
                totalStakedDollar;
            }

            if (
              totalExtraPerBlock === 0 ||
              totalSlushPerBlock === 0 ||
              isNaN(totalExtraPerBlock) ||
              isNaN(totalSlushPerBlock) ||
              totalExtraPerBlock === Infinity ||
              totalSlushPerBlock === Infinity
            ) {
              totalExtraPerBlock = 0;
              totalSlushPerBlock = 0;
            }
            return {
              ...vault,
              totalStaked: Number(totalStaked),
              totalStakedDollar: Number(totalStakedDollar),
              myBalance: Number(userBalance),
              myBalanceNonDollar: userBalaceNonDollar,
              myStake: myStake,
              myslushEarning: Number(myslushEarning),
              mywtlosEarning: Number(mywtlosEarning),
              myStakeDollar: Number(myStakeDollar),
              totalExtraPerBlock: Number(totalExtraPerBlock).toFixed(2),
              totalSlushPerBlock: Number(totalSlushPerBlock).toFixed(2),
            };
          })
        );

        setVaults(updatedVaults);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsDataLoading(false);
      }
    };

    if (isConnected) {
      fetchData();
    }
  }, [isConnected, address]);
  useEffect(() => {
    if (chain) {
      setDiff(chain.id); // Update the diff state with the current network ID
    }
  }, [chain]);

  const getContract = (address: string, abi: any, provider: any) => {
    const web3 = new Web3(provider);
    return new web3.eth.Contract(abi, address);
  };
  const switchNetwork = useSwitchNetwork();
  const networkSwitchHandler = (networkId: number) => {
    (switchNetwork as any).switchNetwork(networkId);
  };
  const representativeBodyTemplate = (rowData: VaultItem) => {
    const [part1, part2] = rowData.displayName.split("-");

    const img1 = `../image/assets/${part1.toLowerCase()}.png`;
    const img2 = `../image/assets/${part2.toLowerCase()}.png`;
    const targetUrl = `https://app.rivera.money/#/vault/${rowData.vaultAddress}_40`;
    return (
      <div className="justify-content-between d-flex align-items-start whitespace-nowrap">
        <div className="d-flex align-items-start im_dark_lightText">
          <div className="d-flex">
            <img src={img1} alt="img1" className="btc_asst_img_width" />
            <img src={img2} alt="img2" className="btc_img_width" />
          </div>
          <a href={targetUrl} className="cust-link">
            <div>
              <div className="vault_header_font_size">
                {rowData.displayName}
              </div>
              <div className="card_snd_clr fnt_14 notMobileDevice imLight">
                {rowData.strategyType}
              </div>
            </div>
          </a>
          <a href={targetUrl} className="">
            <img src={arrowUpImg} alt="website" className="" />
          </a>
        </div>
      </div>
    );
  };

  const representativeBodyTemplateAPR = (rowData: VaultItem) => {
    const img1 = `../image/assets/slush.png`;
    const img2 = `../image/assets/wtlos.png`;

    return (
      <div className="d-flex gap2 flex-column ">
        <div className="d-flex">
          <span className="redHatFont fnt_wgt_600 flexTwoBtn2 ">
            <img src={img1} className="btc_asst_img_width_10" alt="coin" />
            {isDataLoading ? (
              <>
                <Skeleton width="3rem" className="mb-2"></Skeleton>
              </>
            ) : (
              <>{fnum(rowData.totalSlushPerBlock)}%</>
            )}
          </span>
        </div>
        <div className="d-flex">
          <span className="redHatFont fnt_wgt_600 flexTwoBtn2 ">
            <img src={img2} className="btc_asst_img_width_10" alt="coin" />
            {isDataLoading ? (
              <>
                <Skeleton width="3rem" className="mb-2"></Skeleton>
              </>
            ) : (
              <>{fnum(rowData.totalExtraPerBlock)}%</>
            )}
          </span>
        </div>
      </div>
    );
  };

  const representativeBodyTemplateEarning = (rowData: VaultItem) => {
    const img1 = `../image/assets/slush.png`;
    const img2 = `../image/assets/wtlos.png`;
    return (
      // <div className="d-flex gap2 flex-column ">
      //   <div className="d-flex">
      //     <span className="redHatFont fnt_wgt_600 flexTwoBtn2">
      //         <img src={img1} className="btc_asst_img_width_10" alt="coin" />
      //       {isDataLoading ? (
      //         <>
      //           <Skeleton
      //             width="3rem"
      //             className="mb-2"
      //           ></Skeleton>
      //         </>
      //       ) : (
      //         <>
      //           {fnum(rowData.myslushEarning)}
      //         </>
      //       )}
      //     </span>
      //   </div>
      //   <div className="d-flex">
      //     <span className="redHatFont fnt_wgt_600 flexTwoBtn2">
      //       <img src={img2} className="btc_asst_img_width_10" alt="coin" />
      //       {isDataLoading ? (
      //         <>
      //           <Skeleton
      //             width="3rem"
      //             className="mb-2"
      //           ></Skeleton>
      //         </>
      //       ) : (
      //         <>
      //           {fnum(rowData.mywtlosEarning)}
      //         </>
      //       )}
      //     </span>
      //   </div>
      // </div>
      <div className="d-flex gap2 flex-column">
        <div className="d-flex">
          <span className="redHatFont fnt_wgt_600 flexTwoBtn2 ">
            <img src={img1} className="btc_asst_img_width_10" alt="coin" />
            {isDataLoading ? (
              <Skeleton width="3rem" className="mb-2" />
            ) : (
              fnum(rowData.myslushEarning)
            )}
          </span>
        </div>
        <div className="d-flex">
          <span className="redHatFont fnt_wgt_600 flexTwoBtn2">
            <img src={img2} className="btc_asst_img_width_10" alt="coin" />
            {isDataLoading ? (
              <Skeleton width="3rem" className="mb-2" />
            ) : (
              fnum(rowData.mywtlosEarning)
            )}
          </span>
        </div>
      </div>
    );
  };
  const representativeBodyTemplateSybmbol = (rowData: VaultItem) => {
    const targetUrl = `https://app.rivera.money/#/vault/${rowData.vaultAddress}_40`;
    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="d-flex ">
          <a
            href={targetUrl}
            className="redHatFont fnt_wgt_600 cust-link"
            style={{ whiteSpace: "nowrap" }}
          >
            {rowData.symbol}
          </a>
          <a href={targetUrl} className="">
            <img src={arrowUpImg} alt="website" className="" />
          </a>
        </div>
      </div>
    );
  };
  const representativeBodyTemplateMasterBalance = (rowData: VaultItem) => {
    setVaultNumberBalance(
      rowData?.symbol === "RIV-01-01-Z"
        ? 0
        : rowData?.symbol === "RIV-01-03-Y"
        ? 1
        : rowData?.symbol === "RIV-01-04-Y"
        ? 2
        : undefined
    );

    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="d-flex">
          <span className="redHatFont fnt_wgt_600">
            {isDataLoading ? (
              <>
                <Skeleton width="3rem" className="mb-2"></Skeleton>
              </>
            ) : (
              <>{fnum(rowData.myBalanceNonDollar)}</>
            )}
          </span>
        </div>
        <div
          className="flex-exchng font_300"
          style={{ whiteSpace: "pre-wrap" }}
        >
          <span>
            {isDataLoading ? (
              <>
                <Skeleton width="3rem" className="mb-2"></Skeleton>
              </>
            ) : (
              <>(${fnum(rowData.myBalance)})</>
            )}
          </span>
        </div>
      </div>
    );
  };
  const representativeBodyTemplateMasterMYStake = (rowData: VaultItem) => {
    setVaultNumberMyStake(
      rowData?.symbol === "RIV-01-01-Z"
        ? 0
        : rowData?.symbol === "RIV-01-03-Y"
        ? 1
        : rowData?.symbol === "RIV-01-04-Y"
        ? 2
        : undefined
    );

    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="d-flex">
          <span className="redHatFont fnt_wgt_600 ">
            {isDataLoading ? (
              <>
                <Skeleton width="3rem" className="mb-2"></Skeleton>
              </>
            ) : (
              <>{fnum(rowData.myStake)}</>
            )}
          </span>
        </div>
        <div
          className="flex-exchng font_300"
          style={{ whiteSpace: "pre-wrap" }}
        >
          <span>
            {isDataLoading ? (
              <>
                <Skeleton width="3rem" className="mb-2"></Skeleton>
              </>
            ) : (
              <>(${fnum(rowData.myStakeDollar)})</>
            )}
          </span>
        </div>
      </div>
    );
  };

  const representativeBodyTemplateTotalStaked = (rowData: VaultItem) => {
    setVaultNumberTotalStaked(
      rowData?.symbol === "RIV-01-01-Z"
        ? 0
        : rowData?.symbol === "RIV-01-03-Y"
        ? 1
        : rowData?.symbol === "RIV-01-04-Y"
        ? 2
        : undefined
    );

    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="d-flex">
          <span className="redHatFont fnt_wgt_600 ">
            {isDataLoading ? (
              <>
                <Skeleton width="3rem" className="mb-2"></Skeleton>
              </>
            ) : (
              <>{fnum(rowData.totalStaked)}</>
            )}
          </span>
        </div>
        <div
          className="flex-exchng font_300"
          style={{ whiteSpace: "pre-wrap" }}
        >
          <span>
            {isDataLoading ? (
              <>
                <Skeleton width="3rem" className="mb-2"></Skeleton>
              </>
            ) : (
              <>(${fnum(rowData.totalStakedDollar)})</>
            )}
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast} />

      <div className="custom-container">
        {isConnected ? (
          <div className="second_section_xRiv outer_section_first_xRiv">
            <div className="dsp_cont_first_section">
              <div className="wdth_40_first">
                <div className="holding_header_inner mt-2 stakeText redHatFont">
                  Stake Yield Tokens to earn $TLOS and $SLUSH{" "}
                </div>
              </div>
              <div>
                <img
                  height={200}
                  src={coins}
                  alt="rocket img"
                  className="manageImage notMobileDevice"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="second_section_xRiv outer_section_first_xRiv">
            <div className="dsp_cont_first_section">
              <div className="wdth_40_first">
                <div className="holding_header_inner mb-2 stakeText redHatFont">
                  Stake Yield Tokens to earn $TLOS and $SLUSH{" "}
                </div>
                <div className=" manage-earn-claimSection align-items-center">
                  <div className="dsp_around mb-2">
                    <ConnectBtn />
                  </div>
                </div>
              </div>
              <div>
                <img
                  height={200}
                  src={coins}
                  alt="rocket img"
                  className="manageImage notMobileDevice"
                />
              </div>
            </div>
          </div>
        )}
        <h2 className="redHatFont history_txt1 mt-1 ml-105">Active Vaults</h2>

        <div className="custom-container border2 mrg_all">
          <DataTable
            scrollable
            tableStyle={{ minWidth: "50rem" }}
            className="p-datatable-striped"
            value={vaults}
          >
            <Column
              style={{ width: "100px" }}
              headerClassName="im_header txt_gray"
              className="im_dark_lightBG"
              bodyClassName="im_dark_lightText "
              body={representativeBodyTemplate}
              header="Vault"
            />
            <Column
              style={{ width: "100px" }}
              headerClassName="im_header txt_gray whitespace-nowrap"
              className="im_dark_lightBG"
              bodyClassName="im_dark_lightText "
              body={representativeBodyTemplateSybmbol}
              header="Yield Token"
            />
            <Column
              style={{ width: "100px" }}
              headerClassName="im_header txt_gray whitespace-nowrap"
              className="im_dark_lightBG"
              bodyClassName="im_dark_lightText whitespace-nowrap"
              body={representativeBodyTemplateAPR}
              header="APR"
            />
            <Column
              style={{ width: "100px" }}
              headerClassName="im_header txt_gray "
              className="im_dark_lightBG"
              bodyClassName="im_dark_lightText "
              body={representativeBodyTemplateTotalStaked}
              header="Total Staked"
            />
            <Column
              style={{ width: "100px" }}
              headerClassName="im_header txt_gray "
              className="im_dark_lightBG"
              bodyClassName="im_dark_lightText "
              body={representativeBodyTemplateMasterBalance}
              header="My Balance"
            />
            <Column
              style={{ width: "100px" }}
              headerClassName="im_header txt_gray "
              className="im_dark_lightBG"
              bodyClassName="im_dark_lightText"
              body={representativeBodyTemplateMasterMYStake}
              header="My Stake"
            />
            <Column
              style={{ width: "100px" }}
              headerClassName="im_header txt_gray whitespace-nowrap"
              className="im_dark_lightBG"
              bodyClassName="im_dark_lightText whitespace-nowrap "
              body={representativeBodyTemplateEarning}
              header="Earnings"
            />

            <Column
              headerClassName="im_header txt_gray "
              className="im_dark_lightBG"
              bodyClassName="im_header txt_gray "
              style={{ width: "120px" }}
              alignFrozen="right"
              body={(rowData: VaultItem) =>
                isConnected ? (
                  diff !== Number(40) ? (
                    <button
                      className="btn btn-riv-primary wdth_100 redHatFont fnt_wgt_600 whitespace-nowrap"
                      onClick={() => {
                        networkSwitchHandler(40);
                      }}
                    >
                      Switch to telos
                    </button>
                  ) : (
                    <ClaimButton
                      vaultName={rowData.displayName}
                      symbol={rowData.symbol}
                      Img1={`../image/assets/${
                        rowData.displayName.toLowerCase().split("-")[0]
                      }.png`}
                      Img2={`../image/assets/${
                        rowData.displayName.toLowerCase().split("-")[1]
                      }.png`}
                      addressNew={rowData.vaultAddress}
                      rpc={rowData.chainRPC}
                      denominationDecimal={rowData.denominationDecimal}
                    />
                  )
                ) : (
                  <ConnectBtn />
                )
              }
            />
            <Column
              headerClassName="im_header txt_gray"
              className="im_header txt_gray"
              bodyClassName=" im_header txt_gray"
              style={{ width: "120px" }}
              alignFrozen="right"
              frozen
              body={(rowData: VaultItem) =>
                isConnected ? (
                  diff !== Number(40) ? (
                    <button
                      className="btn btn-riv-primary wdth_100 whitespace-nowrap redHatFont fnt_wgt_600"
                      onClick={() => {
                        networkSwitchHandler(40);
                      }}
                    >
                      Switch to telos
                    </button>
                  ) : (
                    <ManageBtn
                      vaultName={rowData.displayName}
                      symbol={rowData.symbol}
                      Img1={`../image/assets/${
                        rowData.displayName.toLowerCase().split("-")[0]
                      }.png`}
                      Img2={`../image/assets/${
                        rowData.displayName.toLowerCase().split("-")[1]
                      }.png`}
                      addressNew={rowData.vaultAddress}
                      rpc={rowData.chainRPC}
                      denominationDecimal={rowData.denominationDecimal}
                      denominationAssetAddress={
                        rowData.denominationAssetAddress
                      }
                      totalAssets={rowData.totalAssets}
                      totalSupply={rowData.totalSupply}
                      myBalance={rowData.myBalance}
                      myBalanceNonDollar={rowData.myBalanceNonDollar}
                      mystake={rowData.myStake}
                    />
                  )
                ) : (
                  <ConnectBtn />
                )
              }
            />
          </DataTable>
        </div>
      </div>
    </>
  );
}
export default Manage;
