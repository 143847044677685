//import { getDefaultWallets } from '@rainbow-me/rainbowkit'
import { Chain, getDefaultWallets } from "@rainbow-me/rainbowkit";
import { configureChains, createClient } from "wagmi";
import { arbitrum, bsc, mainnet, optimism, polygon } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { alchemyProvider } from "wagmi/providers/alchemy";

const mantaChain: Chain = {
  id: 169,
  name: "Manta Pacific",
  network: "ETH",
  iconUrl: "../img/manta.png",
  //iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: "Manta Pacific",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: ["https://pacific-rpc.manta.network/http"],
    },
    public: {
      http: ["https://pacific-rpc.manta.network/http"],
    },
  },
  blockExplorers: {
    default: {
      name: "SnowTrace",
      url: "https://pacific-explorer.manta.network",
    },
  },
};

const mantleChain: Chain = {
  id: 5000,
  name: "Mantle Network",
  network: "MNT",
  iconUrl: "../img/mantle.svg",
  //iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: "Mantle Network",
    symbol: "MNT",
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.mantle.xyz"],
    },
    public: {
      http: ["https://rpc.mantle.xyz"],
    },
  },
  blockExplorers: {
    default: { name: "SnowTrace", url: "https://explorer.mantle.xyz" },
  },
};

const arbitrumOne: Chain = {
  id: 42161,
  name: "arbitrum",
  network: "ETH",
  iconUrl: "../img/mantle.svg",
  //iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: "arbitrum",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: ["https://arb1.arbitrum.io/rpc"],
    },
    public: {
      http: ["https://arb1.arbitrum.io/rpc"],
    },
  },
  blockExplorers: {
    default: { name: "SnowTrace", url: "https://arbiscan.io/" },
  },
};

const telosMainnetChain: Chain = {
  id: 40,
  name: "Telos",
  network: "TLOS",
  iconUrl: "../img/telos.png",
  nativeCurrency: {
    decimals: 18,
    name: "Telos",
    symbol: "TLOS",
  },
  rpcUrls: {
    default: {
      http: ["https://mainnet.telos.net/evm"],
    },
    public: {
      http: ["https://mainnet.telos.net/evm"],
    },
  },
  blockExplorers: {
    default: { name: "SnowTrace", url: "https://teloscan.io" },
  },
};

const mantleTestChain: Chain = {
  id: 5001,
  name: "Mantle Testnet",
  network: "MNT",
  iconUrl: "../img/mantle.svg",
  //iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: "Mantle Testnet",
    symbol: "MNT",
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.testnet.mantle.xyz"],
    },
    public: {
      http: ["https://rpc.testnet.mantle.xyz"],
    },
  },
  blockExplorers: {
    default: { name: "SnowTrace", url: "https://explorer.testnet.mantle.xyz" },
  },
  testnet: true,
};

const bnbLocalChain: Chain = {
  id: 56,
  name: "BNB Smart Chain",
  network: "BNB",
  iconUrl: "../img/bnbLogo.svg",
  //iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: "BNB Smart Chain (BNB)",
    symbol: "BNB",
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.ankr.com/bsc"],
    },
    public: {
      http: ["https://rpc.ankr.com/bsc"],
    },
  },
  blockExplorers: {
    default: { name: "SnowTrace", url: "https://bscscan.com/" },
  },
  testnet: true,
};

const blastSepolia: Chain = {
  id: 168587773,
  name: "Blast Sepolia",
  network: "ETH",
  iconUrl: "../img/bnbLogo.svg",
  //iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: "Blast Sepolia (ETH)",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: ["https://sepolia.blast.io"],
    },
    public: {
      http: ["https://sepolia.blast.io"],
    },
  },
  blockExplorers: {
    default: { name: "SnowTrace", url: "https://testnet.blastscan.io/" },
  },
  testnet: true,
};

const ethMainnet: Chain = {
  id: 1,
  name: "Ethereum Mainnet",
  network: "ETH",
  iconUrl: "../img/eth.svg",
  //iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: "Ethereum Mainnet (ETH)",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: ["https://mainnet.infura.io/v3/"],
    },
    public: {
      http: ["https://mainnet.infura.io/v3/"],
    },
  },
  blockExplorers: {
    default: { name: "SnowTrace", url: "https://etherscan.io" },
  },
};

// const polygonMainnet: Chain = {
//   id: 137,
//   name: "Polygon Mainnet",
//   network: "MATIC",
//   iconUrl: "../img/eth.svg",
//   //iconBackground: '#fff',
//   nativeCurrency: {
//     decimals: 18,
//     name: "Polygon Mainnet (MATIC)",
//     symbol: "MATIC",
//   },
//   rpcUrls: {
//     default: {
//       http: ["https://polygon-mainnet.infura.io"],
//     },
//     public: {
//       http: ["https://polygon-mainnet.infura.io"],
//     },
//   },
//   blockExplorers: {
//     default: { name: "SnowTrace", url: "https://polygonscan.com/" },
//   },
//   testnet: true,
// };

const zetaTestnet: Chain = {
  id: 7001,
  name: "ZetaChain Testnet",
  network: "ZETA",
  iconUrl: "../img/eth.svg",
  // iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: "ZetaChain Testnet (ZETA)",
    symbol: "ZETA",
  },
  rpcUrls: {
    default: {
      http: ["https://zetachain-athens-evm.blockpi.network/v1/rpc/public"],
    },
    public: {
      http: ["https://zetachain-athens-evm.blockpi.network/v1/rpc/public"],
    },
  },
  blockExplorers: {
    default: { name: "SnowTrace", url: "" },
  },
  testnet: true,
};

const base: Chain = {
  id: 8453,
  name: "Base",
  network: "ETH",
  iconUrl: "../img/eth.svg",
  // iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: "Base (ETH)",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: ["https://mainnet.base.org"],
    },
    public: {
      http: ["https://mainnet.base.org"],
    },
  },
  blockExplorers: {
    default: { name: "SnowTrace", url: "https://basescan.org" },
  },
  testnet: true,
};

const bsquared: Chain = {
  id: 223,
  name: "B-squared",
  network: "BTC",
  iconUrl: "../img/b201.svg",
  //iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: "B-squared",
    symbol: "BTC",
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.bsquared.network"],
    },
    public: {
      http: ["https://rpc.bsquared.network"],
    },
  },
  blockExplorers: {
    default: {
      name: "SnowTrace",
      url: "https://testnet-explorer.bsquared.network/",
    },
  },
};
const degen: Chain = {
  id: 666666666,
  name: "Degen L3",
  network: "DEGEN",
  iconUrl: "../img/degen01.svg",
  //iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: "degen",
    symbol: "DEGEN",
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.degen.tips"],
    },
    public: {
      http: ["https://rpc.degen.tips"],
    },
  },
  blockExplorers: {
    default: { name: "SnowTrace", url: "https://explorer.degen.tips/" },
  },
};
const polygonPoS: Chain = {
  id: 137,
  name: "PolygonPoS",
  network: "MATIC",
  iconUrl: "../img/eth.svg",
  //iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: "Polygon Mainnet (MATIC)",
    symbol: "MATIC",
  },
  rpcUrls: {
    default: {
      http: ["https://polygon-rpc.com/"],
    },
    public: {
      http: ["https://polygon-rpc.com/"],
    },
  },
  blockExplorers: {
    default: { name: "SnowTrace", url: "https://polygonscan.com/" },
  },
  testnet: true,
};
const coredao: Chain = {
  id: 1116,
  name: "coredao",
  network: "CORE",
  iconUrl: "../img/eth.svg",
  //iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: "CORE",
    symbol: "CORE",
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.coredao.org"],
    },
    public: {
      http: ["https://rpc.coredao.org"],
    },
  },
  blockExplorers: {
    default: { name: "SnowTrace", url: "https://scan.coredao.org/" },
  },
  testnet: true,
};
const { chains, provider } = configureChains(
  [
    mantleChain,
    mantaChain,
    telosMainnetChain,
    arbitrumOne,
    bnbLocalChain,
    blastSepolia,
    ethMainnet,
    // polygonMainnet,
    zetaTestnet,
    base,
    bsquared,
    degen,
    polygonPoS,
    coredao,
  ],
  [
    alchemyProvider({ apiKey: process.env.ALCHEMY_ID as string }),
    publicProvider(),
  ]
);

const { connectors } = getDefaultWallets({
  appName: "My wagmi + RainbowKit App",
  chains,
  projectId: "ee56c353983496c87480ff2ae841a933",
});

export const client = createClient({
  autoConnect: true,
  connectors,
  provider,
});

export { chains };
