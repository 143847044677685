import React from 'react';
import DoughnutChartDemo from './chart';

interface DoughnutChartContainerProps {
  data: { value: number; color: string; className?: string | undefined; image?: string | undefined; label: string }[][];
  title: string;
  showLabel: boolean;
  showValue: boolean;
}

const DoughnutChartContainer: React.FC<DoughnutChartContainerProps> = ({ data, title, showLabel, showValue }) => {
  return (
    <div className="doughnut  border2">
      <h3 className="redHatFont fontbold ml-1 mt-1">{title}</h3>
      <div className="flexDo align-items-center">
        <div className="month-labels">
          <DoughnutChartDemo data={data} />
        </div>
        <div>
          <div className="flexDo redHatFont values-container">
            <div className="value">
              {data[0].map((item, subKey) => (
                <div key={subKey} className="grid mrgn_btm_15 gap-6 grid-cols-3 gap-x-2 align-items-center value-item">
                  {item.image && <img src={item.image} alt="token" className="min_1r" />}
                  {showLabel ? <p className="headingDo width_4">{item.label}</p> : null}
                  {showValue ? <p className="font_181 ml-1">{item.value}%</p> : null}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoughnutChartContainer;
